// FHT Swagger jQuery client V2
/**
 * FHT.Core.Web.Models.EnterpriseCenterMenu, FHT.Core.Web, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 */
/**
* FHT.Core.Web.Models.EnterpriseCenterMenu, FHT.Core.Web, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
*/
// mark 1 model enum
export enum EnterpriseCenterMenuEnum {
    EnterpriseHome = "EnterpriseHome",
    ProductManagement = "ProductManagement",
    ProductList = "ProductList",
    CompanyManagement = "CompanyManagement",
    CompanyAuthentication = "CompanyAuthentication",
    CompanyProfile = "CompanyProfile",
    FriendlyLink = "FriendlyLink",
    CreateArticle = "CreateArticle",
    SiteRenovation = "SiteRenovation",
    EditNavigations = "EditNavigations",
    MemberManagement = "MemberManagement",
    MemberInvitations = "MemberInvitations",
    MemberApplications = "MemberApplications",
    Statistics = "Statistics",
    EditArticleTypes = "EditArticleTypes",
    ArticleList = "ArticleList",
    SelectTemplate = "SelectTemplate",
    CreateProduct = "CreateProduct",
    EditCustomCategories = "EditCustomCategories",
    SEOSetting = "SEOSetting",
    ArticleManagement = "ArticleManagement",
    SiteExamination = "SiteExamination",
    CreateProductFinished = "CreateProductFinished",
    CreateArticleFinished = "CreateArticleFinished"
}
