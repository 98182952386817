// FHT Swagger jQuery client V2
/**
 * FHT.Common.Types.CompanyMemberType, FHT.Common.Types, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null
 */
/**
* FHT.Common.Types.CompanyMemberType, FHT.Common.Types, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null
*/
// mark 1 model enum
export enum CompanyMemberTypeEnum {
    MainAdmin = "MainAdmin",
    SubAdmin = "SubAdmin",
    Member = "Member"
}
