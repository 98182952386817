import Vue from "vue";
import Vuex from "vuex";
import {
  LOGIN_ACTION,
  LOGOUT_ACTION,
  LOAD_MY_USER_INFO,
  LOAD_MY_COMPANY_MEMBER_INFO,
  RELOAD_MY_COMPANY_MEMBER_INFO,
  LOAD_NOTIFICATION_COUNT,
  LOAD_PRODUCT_COUNT,
  LOAD_PRODUCT_BADGE_ALERT_STATE,
  LOAD_INCOMMING_COMPANY_MEMBER_APPLICATION_COUNT,
  LOAD_INVITING_COMPANY_COUNT,
  LOAD_APPLY_REQUEST_COUNT,
  LOAD_MY_PROFILE,
  MARK_LAST_BROADCAST_ID,
  MARK_LAST_NOTIFICATION_ID,
  MARK_LAST_APPLY_REQUEST_ID,
  MARK_LAST_INVITING_COMPANY_ID,
  MARK_LAST_INCOMMING_COMPANY_MEMBER_APPLICATION_ID,
  LOAD_MY_COMPANY_PROFILE,
} from "./actions";

import {
  SAVE_TOKEN_MUTATION,
  DELETE_TOKEN_MUTATION,
  SAVE_MY_BASIC_INFO_MUTATION,
  SAVE_NOTIFICATION_COUNT,
  SAVE_PRODUCT_COUNT,
  SAVE_PRODUCT_BADGE_ALERT_STATE,
  SAVE_INCOMMING_COMPANY_MEMBER_APPLICATION_COUNT,
  SAVE_INVITING_COMPANY_COUNT,
  SAVE_APPLY_REQUEST_COUNT,
  SAVE_MY_PROFILE_MUTATION,
  SAVE_MY_COMPANY_MUTATION,
} from "./mutations";
import assert from "assert";
import { basicAccountInfoManager } from "@/pages/LayoutFeatures/Account/basicAccountInfoManager";
import { isNumber } from "lodash";
import { getAuthHash, persistentStorage } from "@/utils";
import { getProductCountUserActionManager } from "@/pages/Center/productCountUtil";
import { ExpirableActionPayload } from "@/expirableActionManager";
import { AccountApiApi } from "@/apis/api/AccountApiApi";
import { defaultHeaders } from "@/apis/api/api";
import { CompanyApiApi } from "@/apis/api/CompanyApiApi";
import { CompanyMemberApiApi } from "@/apis/api/CompanyMemberApiApi";
import { NotificationApiApi } from "@/apis/api/NotificationApiApi";
import { ProductApiApi } from "@/apis/api/ProductApiApi";
import { CompanyMemberTypeEnum } from "@/apis/model/CompanyMemberTypeEnum";
import { MyBasicAccountInfoViewModel } from "@/apis/model/MyBasicAccountInfoViewModel";
import { MyCompanyProfileViewModel } from "@/apis/model/MyCompanyProfileViewModel";
import { MyProfileViewModel } from "@/apis/model/MyProfileViewModel";

Vue.config.devtools = !!process.env.DEVTOOL;

Vue.config.productionTip = false;

Vue.use(Vuex);

console.log("VUE STORE LOADED");

export interface IStoreState {
  token: string | undefined;
  me: MyBasicAccountInfoViewModel | undefined;
  myProfile: MyProfileViewModel | undefined;
  myCompany: MyCompanyProfileViewModel | undefined;
  lastNotificationId: number | undefined;
  notificationCount: number | undefined;
  lastBroadcastId: number | undefined;
  broadcastCount: number | undefined;
  productCount: number | undefined;
  productBadgeAlertState: ExpirableActionPayload<boolean> | undefined;
  invitingCompaniesCount: number | undefined;
  lastInvitingCompanyId: number | undefined;
  applyRequestCount: number | undefined;
  lastApplyRequestId: number | undefined;
  lastIncommingCompanyMemberApplicationId: number | undefined;
  incommingCompanyMemberApplicationCount: number | undefined;
}

interface INotificationCountPayload {
  lastNotificationId: number | undefined;
  notificationCount: number;
  lastBroadcastId: number | undefined;
  broadcastCount: number;
}

interface IProductCountPayload {
  productCount: number;
}

interface IProductBadgeAlertStatePayload {
  productCount: number;
  productBadgeAlertState: ExpirableActionPayload<boolean> | undefined;
}

interface IInvitingCompanyCountPayload {
  invitingCompaniesCount: number | undefined;
  lastInvitingCompanyId: number | undefined;
}

interface IApplyRequestCountPayload {
  applyRequestCount: number | undefined;
  lastApplyRequestId: number | undefined;
}

interface IIncommingCompanyMemberApplicationCountPayload {
  incommingCompanyMemberApplicationCount: number | undefined;
  lastIncommingCompanyMemberApplicationId: number | undefined;
}

const initialState: IStoreState = {
  token: undefined,
  me: undefined,
  myProfile: undefined,
  myCompany: undefined,
  lastNotificationId: undefined,
  notificationCount: undefined,
  lastBroadcastId: undefined,
  broadcastCount: undefined,
  productCount: undefined,
  productBadgeAlertState: undefined,
  invitingCompaniesCount: undefined,
  lastInvitingCompanyId: undefined,
  applyRequestCount: undefined,
  lastApplyRequestId: undefined,
  incommingCompanyMemberApplicationCount: undefined,
  lastIncommingCompanyMemberApplicationId: undefined,
};

const accountApi = new AccountApiApi();
const companyMemberApi = new CompanyMemberApiApi();

const LAST_NOTIFICATION_ID_KEY = "_lastNotificationId_";
async function getLastNotificationId() {
  const id = await persistentStorage.getItem(LAST_NOTIFICATION_ID_KEY);
  return Number(id) || undefined;
}

const LAST_BROADCAST_ID_KEY = "_lastBroadCastId_";
async function getLastBroadcastId() {
  const id = await persistentStorage.getItem(LAST_BROADCAST_ID_KEY);
  return Number(id) || undefined;
}

const LAST_INVITING_COMPANY_ID_KEY = "_lastInvitingCompanyId_";
async function getLastInvitingCompanyId() {
  const id = await persistentStorage.getItem(LAST_INVITING_COMPANY_ID_KEY);
  return Number(id) || undefined;
}

const LAST_APPLY_REQUEST_ID_KEY = "_lastApplyRequestId_";
async function getLastApplyRequestId() {
  const id = await persistentStorage.getItem(LAST_APPLY_REQUEST_ID_KEY);
  return Number(id) || undefined;
}

const LAST_INCOMMING_COMPANY_MEMBER_APPLICATION_ID_KEY = "_lastIncommingCompanyMemberApplicationId_";
async function getLastIncommingCompanyMemberApplicationId() {
  const id = await persistentStorage.getItem(LAST_INCOMMING_COMPANY_MEMBER_APPLICATION_ID_KEY);
  return Number(id) || undefined;
}

const store = new Vuex.Store({
  state: initialState,
  getters: {
    isInitialized(state) {
      return !!state.me;
    },
  },
  mutations: {
    [SAVE_TOKEN_MUTATION](state: IStoreState, token: string) {
      state.token = token;
      console.log("SET AUTHORIZATION TOKEN HEADER !!!!!!!!!!");
      defaultHeaders.Authorization = "bearer " + token;
    },
    [SAVE_MY_BASIC_INFO_MUTATION](state: IStoreState, payload: MyBasicAccountInfoViewModel) {
      assert(payload, "payload is falsy");
      assert(payload.ClientId, "payload.ClientId falsy");
      state.me = payload;
      if (!payload.CompanyId) {
        state.myCompany = undefined;
        state.incommingCompanyMemberApplicationCount = undefined;
        if (state.myProfile) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          state.myProfile.User.Company = undefined;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          state.myProfile.User.User.CompanyId = undefined;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          state.myProfile.User.User.CompanyMemberType = undefined;
        }
      } else {
        //has company
        state.invitingCompaniesCount = undefined;
        state.applyRequestCount = undefined; //inviting companies or member applications no longer relavant after joining a company
      }
    },
    [SAVE_MY_PROFILE_MUTATION](state: IStoreState, payload: MyProfileViewModel) {
      assert(payload.RealNameStatus, "payload.RealNameStatus falsy");
      state.myProfile = payload;
    },
    [SAVE_MY_COMPANY_MUTATION](state: IStoreState, payload: MyCompanyProfileViewModel) {
      assert(isNumber(payload?.FounderId), "payload.FounderId falsy");
      state.myCompany = payload;
    },
    [SAVE_NOTIFICATION_COUNT](state: IStoreState, payload: INotificationCountPayload) {
      assert(isNumber(payload.broadcastCount), "payload.UnReadBroadcastCount is not a number");
      assert(isNumber(payload.notificationCount), "payload.UnReadBroadcastCount is not a number");
      state.notificationCount = payload.notificationCount;
      state.broadcastCount = payload.broadcastCount;
      state.lastBroadcastId = payload.lastBroadcastId;
      state.lastNotificationId = payload.lastNotificationId;
    },
    [SAVE_PRODUCT_COUNT](state: IStoreState, payload: IProductCountPayload) {
      assert(isNumber(payload.productCount), "payload.productCount is not a number");
      state.productCount = payload.productCount;
    },
    async [SAVE_PRODUCT_BADGE_ALERT_STATE](state: IStoreState, payload: IProductBadgeAlertStatePayload) {
      assert(isNumber(payload.productCount), "payload.productCount is not a number");
      state.productCount = payload.productCount;
      state.productBadgeAlertState = payload.productBadgeAlertState;
      if (state.productBadgeAlertState) {
        const info = await basicAccountInfoManager.getBasicAccountInfo();
        const productCountUserActionManager = await getProductCountUserActionManager(info.UserId.toString());
        await productCountUserActionManager.saveActionValue(true);
      }
    },
    [SAVE_INVITING_COMPANY_COUNT](state: IStoreState, payload: IInvitingCompanyCountPayload) {
      assert(isNumber(payload.invitingCompaniesCount), "payload.invitingCompaniesCount is not a number");
      state.invitingCompaniesCount = payload.invitingCompaniesCount;
      state.lastInvitingCompanyId = payload.lastInvitingCompanyId;
    },
    [SAVE_APPLY_REQUEST_COUNT](state: IStoreState, payload: IApplyRequestCountPayload) {
      assert(isNumber(payload.applyRequestCount), "payload.applyRequestCount is not number");
      state.applyRequestCount = payload.applyRequestCount;
      state.lastApplyRequestId = payload.lastApplyRequestId;
    },
    [SAVE_INCOMMING_COMPANY_MEMBER_APPLICATION_COUNT](
      state: IStoreState,
      payload: IIncommingCompanyMemberApplicationCountPayload,
    ) {
      assert(
        isNumber(payload.incommingCompanyMemberApplicationCount),
        "payload.incommingCompanyMemberApplicationCount is not number",
      );
      state.incommingCompanyMemberApplicationCount = payload.incommingCompanyMemberApplicationCount;
      state.lastIncommingCompanyMemberApplicationId = payload.lastIncommingCompanyMemberApplicationId;
    },
    [DELETE_TOKEN_MUTATION](state: IStoreState) {
      state.token = undefined;
      defaultHeaders.Authorization = "";
    },
  },
  actions: {
    async [LOGIN_ACTION]({ dispatch, commit }, token: string) {
      commit(SAVE_TOKEN_MUTATION, token);
      await dispatch(LOAD_MY_USER_INFO, true);
    },
    async [LOAD_MY_USER_INFO]({ dispatch, commit }, refresh = false) {
      const info = await basicAccountInfoManager.getBasicAccountInfo(refresh);
      commit(SAVE_MY_BASIC_INFO_MUTATION, info);
      assert(info, "info falsy");
      if (info.UserId) {
        await dispatch(LOAD_MY_PROFILE);
        await dispatch(LOAD_NOTIFICATION_COUNT);
      }
    },
    async [LOAD_MY_COMPANY_MEMBER_INFO]({ dispatch, state }) {
      let info = state.me;
      if (!info) {
        info = await basicAccountInfoManager.getBasicAccountInfo();
      }
      assert(info, "state.me falsy");
      if (info.CompanyId) {
        await dispatch(LOAD_MY_COMPANY_PROFILE);
      }
      if (info.CompanyMemberType) {
        const isAdmin =
          info.CompanyMemberType === CompanyMemberTypeEnum.MainAdmin ||
          info.CompanyMemberType === CompanyMemberTypeEnum.SubAdmin;
        if (isAdmin) {
          await dispatch(LOAD_INCOMMING_COMPANY_MEMBER_APPLICATION_COUNT);
        }
      } else {
        //no company
        await dispatch(LOAD_INVITING_COMPANY_COUNT);
        await dispatch(LOAD_APPLY_REQUEST_COUNT);
      }
    },
    async [RELOAD_MY_COMPANY_MEMBER_INFO]({ dispatch }) {
      await dispatch(LOAD_MY_USER_INFO, true);
      await dispatch(LOAD_MY_COMPANY_MEMBER_INFO);
    },
    [LOGOUT_ACTION]({ commit }) {
      commit(DELETE_TOKEN_MUTATION);
    },
    async [LOAD_MY_PROFILE]({ commit }) {
      const profile = await accountApi.accountApiGetMyProfile({
        "hash.authHash": getAuthHash(),
      });
      commit(SAVE_MY_PROFILE_MUTATION, profile);
    },
    async [MARK_LAST_NOTIFICATION_ID]({ dispatch }, lastNotificationId: number) {
      assert(isNumber(lastNotificationId), "lastNotificationId is not number");
      await persistentStorage.setItem(LAST_NOTIFICATION_ID_KEY, lastNotificationId.toString());
      await dispatch(LOAD_NOTIFICATION_COUNT);
    },
    async [MARK_LAST_BROADCAST_ID]({ dispatch }, lastBroadcastId: number) {
      assert(isNumber(lastBroadcastId), "lastBroadcastId is not number");
      await persistentStorage.setItem(LAST_BROADCAST_ID_KEY, lastBroadcastId.toString());
      await dispatch(LOAD_NOTIFICATION_COUNT);
    },
    async [MARK_LAST_APPLY_REQUEST_ID]({ dispatch }, lastApplyRequestId: number) {
      assert(isNumber(lastApplyRequestId), "lastApplyRequestId is not number");
      await persistentStorage.setItem(LAST_APPLY_REQUEST_ID_KEY, lastApplyRequestId.toString());
      await dispatch(LOAD_APPLY_REQUEST_COUNT);
    },
    async [MARK_LAST_INVITING_COMPANY_ID]({ dispatch }, lastInvitingCompanyId: number) {
      assert(isNumber(lastInvitingCompanyId), "lastInvitingCompanyId is not number");
      await persistentStorage.setItem(LAST_INVITING_COMPANY_ID_KEY, lastInvitingCompanyId.toString());
      await dispatch(LOAD_INVITING_COMPANY_COUNT);
    },
    async [MARK_LAST_INCOMMING_COMPANY_MEMBER_APPLICATION_ID](
      { dispatch },
      lastIncommingCompanyMemberApplicationId: number,
    ) {
      assert(
        isNumber(lastIncommingCompanyMemberApplicationId),
        "lastIncommingCompanyMemberApplicationId is not number",
      );
      await persistentStorage.setItem(
        LAST_INCOMMING_COMPANY_MEMBER_APPLICATION_ID_KEY,
        lastIncommingCompanyMemberApplicationId.toString(),
      );
      await dispatch(LOAD_INCOMMING_COMPANY_MEMBER_APPLICATION_COUNT);
    },
    async [LOAD_MY_COMPANY_PROFILE]({ commit }) {
      const company = await new CompanyApiApi().companyApiGetMyCompany();
      commit(SAVE_MY_COMPANY_MUTATION, company);
    },
    async [LOAD_NOTIFICATION_COUNT]({ commit }) {
      const notificationApi = new NotificationApiApi();
      const lastNotificationId = await getLastNotificationId();
      const lastBroadcastId = await getLastBroadcastId();
      const notiCount = await notificationApi.notificationApiGetNotificationsUnReadCount({
        "args.lastId": lastNotificationId,
      });
      const broadcastCount = await notificationApi.notificationApiGetBroadcastsUnreadCount({
        "args.lastId": lastBroadcastId,
      });
      const payload: INotificationCountPayload = {
        lastNotificationId: lastNotificationId,
        notificationCount: notiCount.UnreadCount,
        lastBroadcastId: lastBroadcastId,
        broadcastCount: broadcastCount.UnreadCount,
      };
      commit(SAVE_NOTIFICATION_COUNT, payload);
    },
    async [LOAD_PRODUCT_COUNT]({ commit }) {
      const productApi = new ProductApiApi();
      const productCount = await productApi.productApiGetMyCompanyProductCount();
      const payload: IProductCountPayload = {
        productCount: productCount.Value,
      };
      commit(SAVE_PRODUCT_COUNT, payload);
    },
    async [LOAD_PRODUCT_BADGE_ALERT_STATE]({ commit }) {
      const productApi = new ProductApiApi();
      const productCount = await productApi.productApiGetMyCompanyProductCount();
      const info = await basicAccountInfoManager.getBasicAccountInfo();
      const productCountUserActionManager = await getProductCountUserActionManager(info.UserId.toString());
      const productCountActionMangerVal = await productCountUserActionManager.getEffectiveValue();
      const payload: IProductBadgeAlertStatePayload = {
        productCount: productCount.Value,
        productBadgeAlertState: productCountActionMangerVal,
      };
      commit(SAVE_PRODUCT_BADGE_ALERT_STATE, payload);
    },
    async [LOAD_INVITING_COMPANY_COUNT]({ commit }) {
      const lastId = await getLastInvitingCompanyId();
      const invitingCompaniesCount = await companyMemberApi.companyMemberApiGetIncomingCompanyMemberInviteRequestsCount(
        { "args.lastId": lastId },
      );
      const payload: IInvitingCompanyCountPayload = {
        invitingCompaniesCount: invitingCompaniesCount.UnreadCount,
        lastInvitingCompanyId: lastId,
      };
      commit(SAVE_INVITING_COMPANY_COUNT, payload);
    },
    async [LOAD_APPLY_REQUEST_COUNT]({ commit }) {
      const lastId = await getLastApplyRequestId();
      const applyRequestCount = await companyMemberApi.companyMemberApiGetOutgoingCompanyMemberApplyRequestUnreadCount({
        "args.lastId": lastId,
      });
      const payload: IApplyRequestCountPayload = {
        applyRequestCount: applyRequestCount.UnreadCount,
        lastApplyRequestId: lastId,
      };
      commit(SAVE_APPLY_REQUEST_COUNT, payload);
    },
    async [LOAD_INCOMMING_COMPANY_MEMBER_APPLICATION_COUNT]({ commit }) {
      const lastId = await getLastIncommingCompanyMemberApplicationId();
      const res = await companyMemberApi.companyMemberApiGetIncomingCompanyMemberApplyRequestsCount({
        "args.lastId": lastId,
      });
      const payload: IIncommingCompanyMemberApplicationCountPayload = {
        incommingCompanyMemberApplicationCount: res.UnreadCount,
        lastIncommingCompanyMemberApplicationId: lastId,
      };
      commit(SAVE_INCOMMING_COMPANY_MEMBER_APPLICATION_COUNT, payload);
    },
  },
});

if (process.env.NODE_ENV !== "development") {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  (async () => {
    const info = await basicAccountInfoManager.getBasicAccountInfo();
    if (info.UserId) {
      await store.dispatch(LOAD_MY_USER_INFO);
    }
  })();
}
export default store;
