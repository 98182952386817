import { CompanyForApplyingViewModel } from "../model/CompanyForApplyingViewModel";
import { MyBasicAccountInfoViewModel } from "../model/MyBasicAccountInfoViewModel";
import { ChangeUserPasswordInputModel } from "../model/ChangeUserPasswordInputModel";
import { ChangePhoneNumberInputModel } from "../model/ChangePhoneNumberInputModel";
import { CheckIfUsedViewModel } from "../model/CheckIfUsedViewModel";
import { Fht1CompanyUserInputModel } from "../model/Fht1CompanyUserInputModel";
import { BoolViewModel } from "../model/BoolViewModel";
import { CreateSmsVerificationCodeInputModel } from "../model/CreateSmsVerificationCodeInputModel";
import { UserProfileInputModel } from "../model/UserProfileInputModel";
import { FindUserPasswordInputModel } from "../model/FindUserPasswordInputModel";
import { UuidViewModel } from "../model/UuidViewModel";
import { Fht1CompanyUserViewModel } from "../model/Fht1CompanyUserViewModel";
import { MyProfileViewModel } from "../model/MyProfileViewModel";
import { QueryResultCompanyInvitingViewModel } from "../model/QueryResultCompanyInvitingViewModel";
import { AccountLoginInputModel } from "../model/AccountLoginInputModel";
import { StringViewModel } from "../model/StringViewModel";
import { OAuthLoginInputModel } from "../model/OAuthLoginInputModel";
import { VerifyResultViewModel } from "../model/VerifyResultViewModel";
import { UuidResultViewModel } from "../model/UuidResultViewModel";
import { AccountRegisterInputModel } from "../model/AccountRegisterInputModel";
import { ChangeFht1AccountInputModel } from "../model/ChangeFht1AccountInputModel";
import { VerifySmsCodeInputModel } from "../model/VerifySmsCodeInputModel";
import { VerifyCodeInputModel } from "../model/VerifyCodeInputModel";
// FHT Swagger jQuery client V2
/* eslint-disable */
import { BaseApi } from "./api";
export class AccountApiApi extends BaseApi {
    public accountApiApplyings = (): Promise<CompanyForApplyingViewModel> => {
        let localVarPath = '/api/applyings';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public accountApiBasicAccountInfo = (p: {
        "hash.authHash": string;
    }): Promise<MyBasicAccountInfoViewModel> => {
        let localVarPath = '/api/accounts/basicAccount';
        this.r(p, ["hash.authHash",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public accountApiCancel = (p: {
        "smsCode": string;
    }): Promise<void> => {
        let localVarPath = '/api/accounts/_cancel';
        this.r(p, ["smsCode",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public accountApiChangePasswordAsync = (p: {
        "model": ChangeUserPasswordInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/accounts/_changepassword';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public accountApiChangePhoneNumber = (p: {
        "model": ChangePhoneNumberInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/accounts/_changephonenumber';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public accountApiCheckIfAccountExistsAsync = (p: {
        "userName": string;
    }): Promise<CheckIfUsedViewModel> => {
        let localVarPath = '/api/accounts/_checkIfAccountExists';
        this.r(p, ["userName",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public accountApiCreateFht1CompanyUser = (p: {
        "inputModel": Fht1CompanyUserInputModel;
    }): Promise<BoolViewModel> => {
        let localVarPath = '/api/fht1CompanyUsers/_create';
        this.r(p, ["inputModel",]);
        let bodyDict = p["inputModel"];
        const _p = p as any;
        delete _p["inputModel"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public accountApiCreateVerifyCode = (p: {
        "model": CreateSmsVerificationCodeInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/accounts/_createVerifyCode';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public accountApiEditProfileAsync = (p: {
        "model": UserProfileInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/accounts/_editProfile';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public accountApiFindPassword = (p: {
        "model": FindUserPasswordInputModel;
    }): Promise<boolean> => {
        let localVarPath = '/api/accounts/_findPassword';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public accountApiGenLoginUuid = (): Promise<UuidViewModel> => {
        let localVarPath = '/api/accounts/_genLoginUuid';
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, {}, bodyDict) as any;
    };
    public accountApiGetFht1CompanyUserByFht1CompanyId = (p: {
        "fht1CompanyId": number;
    }): Promise<Fht1CompanyUserViewModel> => {
        let localVarPath = '/api/fht1CompanyUsers/{fht1CompanyId}'
            .replace('{' + 'fht1CompanyId' + '}', String(p["fht1CompanyId"]));
        this.r(p, ["fht1CompanyId",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public accountApiGetFht1CompanyUserByFht2UserName = (p: {
        "fht2UserName": string;
    }): Promise<Fht1CompanyUserViewModel> => {
        let localVarPath = '/api/fht1CompanyUsers/getForFht2UserName';
        this.r(p, ["fht2UserName",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public accountApiGetMyProfile = (p: {
        "hash.authHash": string;
    }): Promise<MyProfileViewModel> => {
        let localVarPath = '/api/accounts/myProfile';
        this.r(p, ["hash.authHash",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public accountApiGetProfileForEdit = (): Promise<UserProfileInputModel> => {
        let localVarPath = '/api/accounts/_getProfileForEdit';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public accountApiInvitedCompany = (p: {
        "args.currPage": number;
        "args.limit": number;
    }): Promise<QueryResultCompanyInvitingViewModel> => {
        let localVarPath = '/api/invitedcompanies';
        this.r(p, ["args.currPage", "args.limit",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public accountApiLogOff = (): Promise<void> => {
        let localVarPath = '/api/accounts/_logOff';
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, {}, bodyDict) as any;
    };
    public accountApiLoginAsync = (p: {
        "model": AccountLoginInputModel;
    }): Promise<StringViewModel> => {
        let localVarPath = '/api/accounts/_login';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public accountApiOAuthLoginAsync = (p: {
        "model": OAuthLoginInputModel;
    }): Promise<VerifyResultViewModel> => {
        let localVarPath = '/api/accounts/_oauthlogin';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public accountApiPostToFht1UrlAsync = (): Promise<StringViewModel> => {
        let localVarPath = '/api/accounts/_toOldFhtUrl';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public accountApiRefreshQrCodeAsync = (p: {
        "uuid": string;
    }): Promise<UuidResultViewModel> => {
        let localVarPath = '/api/accounts/_refreshQrCode';
        this.r(p, ["uuid",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public accountApiRegister = (p: {
        "model": AccountRegisterInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/accounts/_register';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public accountApiResetAccount = (p: {
        "model": ChangeFht1AccountInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/accounts/_resetAccount';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public accountApiSmsCodeLoginAsync = (p: {
        "model": VerifySmsCodeInputModel;
    }): Promise<VerifyResultViewModel> => {
        let localVarPath = '/api/accounts/_smsCodeLogin';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public accountApiUpdateFht1CompanyUser = (p: {
        "inputModel": Fht1CompanyUserInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/fht1CompanyUsers/_update';
        this.r(p, ["inputModel",]);
        let bodyDict = p["inputModel"];
        const _p = p as any;
        delete _p["inputModel"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public accountApiVerifyAccountAndSmsCode = (p: {
        "model": VerifyCodeInputModel;
    }): Promise<BoolViewModel> => {
        let localVarPath = '/api/accounts/_verifyAccountAndSmsCode';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
}
