import { EligibilityTypeEnum } from "@/apis/model/EligibilityTypeEnum";
import { EnterpriseCenterMenuEnum } from "@/apis/model/EnterpriseCenterMenuEnum";
import { UserCenterMenuEnum } from "@/apis/model/UserCenterMenuEnum";

export const enterpriseCenterEligibilityMap: { [k in EnterpriseCenterMenuEnum]: EligibilityTypeEnum | undefined } = {
  [EnterpriseCenterMenuEnum.EnterpriseHome]: undefined,
  [EnterpriseCenterMenuEnum.ArticleList]: EligibilityTypeEnum.OtherCompanyCommonManagement,
  [EnterpriseCenterMenuEnum.ProductManagement]: undefined,
  [EnterpriseCenterMenuEnum.CompanyManagement]: undefined,
  [EnterpriseCenterMenuEnum.ArticleManagement]: undefined,
  [EnterpriseCenterMenuEnum.MemberManagement]: EligibilityTypeEnum.OtherCompanyCommonManagement,
  [EnterpriseCenterMenuEnum.ProductList]: undefined,
  [EnterpriseCenterMenuEnum.CreateProduct]: EligibilityTypeEnum.Product,
  [EnterpriseCenterMenuEnum.CompanyAuthentication]: EligibilityTypeEnum.OtherCompanyCommonManagement,
  [EnterpriseCenterMenuEnum.CompanyProfile]: EligibilityTypeEnum.OtherCompanyCommonManagement,
  [EnterpriseCenterMenuEnum.FriendlyLink]: EligibilityTypeEnum.OtherCompanyCommonManagement,
  [EnterpriseCenterMenuEnum.CreateArticle]: EligibilityTypeEnum.Article,
  [EnterpriseCenterMenuEnum.SiteRenovation]: EligibilityTypeEnum.OtherCompanyCommonManagement,
  [EnterpriseCenterMenuEnum.EditNavigations]: EligibilityTypeEnum.CompanyVipManagement,
  [EnterpriseCenterMenuEnum.MemberInvitations]: EligibilityTypeEnum.OtherCompanyCommonManagement,
  [EnterpriseCenterMenuEnum.MemberApplications]: EligibilityTypeEnum.OtherCompanyCommonManagement,
  [EnterpriseCenterMenuEnum.Statistics]: undefined,
  [EnterpriseCenterMenuEnum.EditArticleTypes]: EligibilityTypeEnum.CompanyVipManagement,
  [EnterpriseCenterMenuEnum.ArticleList]: undefined,
  [EnterpriseCenterMenuEnum.SelectTemplate]: undefined,
  [EnterpriseCenterMenuEnum.EditCustomCategories]: EligibilityTypeEnum.CompanyVipManagement,
  [EnterpriseCenterMenuEnum.SEOSetting]: EligibilityTypeEnum.OtherCompanyCommonManagement,
  [EnterpriseCenterMenuEnum.SiteExamination]: undefined,
  [EnterpriseCenterMenuEnum.CreateProductFinished]: undefined,
  [EnterpriseCenterMenuEnum.CreateArticleFinished]: undefined,
};

export const userCenterEligibilityMap: { [k in UserCenterMenuEnum]: EligibilityTypeEnum | undefined } = {
  [UserCenterMenuEnum.UserHome]: undefined,
  [UserCenterMenuEnum.UserProfile]: undefined,
  [UserCenterMenuEnum.ViewingHistory]: undefined,
  [UserCenterMenuEnum.CreateTopicPost]: EligibilityTypeEnum.TopicPost,
  [UserCenterMenuEnum.MyCompany]: undefined,
  [UserCenterMenuEnum.MyTopicPosts]: undefined,
  [UserCenterMenuEnum.MyCollections]: undefined,
  [UserCenterMenuEnum.MyNotifications]: undefined,
  [UserCenterMenuEnum.AccountSecurity]: undefined,
  [UserCenterMenuEnum.SecuritySettings]: undefined,
  [UserCenterMenuEnum.AccountBinding]: undefined,
  [UserCenterMenuEnum.InviteApplies]: undefined,
  [UserCenterMenuEnum.CreateCompany]: undefined,
  [UserCenterMenuEnum.JoinCompany]: EligibilityTypeEnum.JoinCompany,
  [UserCenterMenuEnum.CreateTopicPostFinished]: undefined,
};
