// FHT Swagger jQuery client V2
/**
 * FHT.Core.CommonTypes.EligibilityStatus, FHT.Core.CommonTypes, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null
 */
/**
* FHT.Core.CommonTypes.EligibilityStatus, FHT.Core.CommonTypes, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null
*/
// mark 1 model enum
export enum EligibilityStatusEnum {
    Ok = "Ok",
    Warn = "Warn",
    Error = "Error"
}
