import Vue from "vue";

import { getAuthHash } from "@/utils";
import { LoginFinishedEvent, LoginAbortedEvent } from "./customEvents";
import assert from "assert";
import { AccountApiApi } from "@/apis/api/AccountApiApi";
import { MyBasicAccountInfoViewModel } from "@/apis/model/MyBasicAccountInfoViewModel";

function newWrappedContainer() {
  const d = document.createElement("div");
  const inner = document.createElement("div");
  d.appendChild(inner);
  // eslint-disable-next-line compat/compat
  document.body.appendChild(d);
  return [d, inner];
}

type BasicAccountInfoSubScriber = (info: MyBasicAccountInfoViewModel) => void;

class BasicAccountInfoManager {
  private _p: Promise<MyBasicAccountInfoViewModel> | undefined;
  private _subscribers: BasicAccountInfoSubScriber[] = [];

  public onAccountInfoUpdated(subscriber: BasicAccountInfoSubScriber) {
    this._subscribers.push(subscriber);
  }

  private refreshBasicAccountInfo(): Promise<MyBasicAccountInfoViewModel> {
    const p = new AccountApiApi().accountApiBasicAccountInfo({ "hash.authHash": getAuthHash() });
    this._p = p;
    return p;
  }

  public getBasicAccountInfo(refresh = false): Promise<MyBasicAccountInfoViewModel> {
    if (!this._p || refresh) {
      this._p = this.refreshBasicAccountInfo();
    }
    return this._p;
  }

  public async proceedAfterLogin(): Promise<MyBasicAccountInfoViewModel | Error> {
    const loginInfo = await this.getBasicAccountInfo();
    if (loginInfo.UserId) {
      return loginInfo;
    } else {
      const LoginForm = () => import(/* webpackChunkName: "Login" */ "./LoginForm.vue");
      const [container, inner] = newWrappedContainer();
      new Vue({
        render: (h) => h(LoginForm),
      }).$mount(inner);

      let resolveLoginFinished: ((result: MyBasicAccountInfoViewModel | Error) => void) | undefined;
      const loginFinished = new Promise<MyBasicAccountInfoViewModel | Error>((res, rej) => {
        resolveLoginFinished = res;
      });

      $(container).on(LoginFinishedEvent, async () => {
        const info = await this.refreshBasicAccountInfo();
        assert(resolveLoginFinished);
        this._subscribers.forEach((sub) => sub(info));
        resolveLoginFinished(info);
      });

      $(container).on(LoginAbortedEvent, () => {
        assert(resolveLoginFinished);
        resolveLoginFinished(new Error("login aborted"));
      });

      const res = await loginFinished;
      return res;
    }
  }
}

export const basicAccountInfoManager = new BasicAccountInfoManager();
