// FHT Swagger jQuery client V2
/**
 * FHT.Core.Web.Models.UserCenterMenu, FHT.Core.Web, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 */
/**
* FHT.Core.Web.Models.UserCenterMenu, FHT.Core.Web, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
*/
// mark 1 model enum
export enum UserCenterMenuEnum {
    UserHome = "UserHome",
    UserProfile = "UserProfile",
    ViewingHistory = "ViewingHistory",
    CreateTopicPost = "CreateTopicPost",
    MyCompany = "MyCompany",
    MyTopicPosts = "MyTopicPosts",
    MyCollections = "MyCollections",
    MyNotifications = "MyNotifications",
    AccountSecurity = "AccountSecurity",
    InviteApplies = "InviteApplies",
    CreateCompany = "CreateCompany",
    JoinCompany = "JoinCompany",
    CreateTopicPostFinished = "CreateTopicPostFinished",
    SecuritySettings = "SecuritySettings",
    AccountBinding = "AccountBinding"
}
