
import { toISOString  } from './apis/api/api';

import { UserCenterMenuEnum } from "./apis/model/UserCenterMenuEnum"
import { EnterpriseCenterMenuEnum } from "./apis/model/EnterpriseCenterMenuEnum"
import { ProductAndServiceMenuTypeEnum } from "./apis/model/ProductAndServiceMenuTypeEnum"

/* eslint-disable fht-eslint-rules/string-literal-validator */
//AddtionallyRequired must be supplyed by users of url_gen.js
import { AdditionalOpts, getActualHost } from "./AdditionallyRequired";

//add query parameter
export function qadd(url: string, additionalArgs?: {}) {
    if (!additionalArgs) return url;
    const args: string[] = [];
    // tslint:disable-next-line:forin
    for(const key in additionalArgs){
        // tslint:disable-next-line:no-any
        const value = (additionalArgs as any)[key];
        args.push(key + "=" + encodeURIComponent(value.toString()));
    }
    const p = args.join("&");
    if (!p.length) return url;
    return url.indexOf("?") > -1 ? url + "&" + p : url + "?" + p
}
function ec(value: unknown):string | unknown {
    if (value instanceof Date) {
        return toISOString(value);
      } else if (typeof value === "string") {
        return encodeURIComponent(value);
      }
      return value as string;
    }


//smart host transformer, only add host to url if different from current host.

export interface UrlHosts {
    FhtWebUrlRoot: string;
    FhtWebCompanyRoot: string;
    FhtWebMobileCompanyRoot: string;
    FhtWebTopicRoot: string;
    FhtWebDetailRoot: string;
    FhtWebMobileUrlRoot: string;
}

const tr = (()=>{
  const shortenedHostMap: { [k: string]: string} = {
    _a65: "FhtWebUrlRoot",
    _2cc: "FhtWebCompanyRoot",
    _be2: "FhtWebMobileCompanyRoot",
    _bb0: "FhtWebTopicRoot",
    _540: "FhtWebDetailRoot",
    _546: "FhtWebMobileUrlRoot",
  };
  return (url: string, t: string) => {
    const h = shortenedHostMap["_" + t] as string;
    if (!h) throw new Error("oops");
    const host = getActualHost(h);
    return host + url;
  }
})();


export const UrlHelperExtensions = {

  $Home: tr(`/`, "a65") /* FhtWebUrlRoot */,

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $CmsHome(companyId: number, opts?: AdditionalOpts): string{
    return qadd(tr(`/c${ec(companyId)}`, "2cc") /* FhtWebCompanyRoot */, opts);
  },

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $CmsProductList(companyId: number, opts?: AdditionalOpts): string{
    return qadd(tr(`/c${ec(companyId)}/product`, "2cc") /* FhtWebCompanyRoot */, opts);
  },

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $ArticleType(companyId: number, articleTypeId: number, opts?: AdditionalOpts): string{
    return qadd(tr(`/c${ec(companyId)}/n${ec(articleTypeId)}`, "2cc") /* FhtWebCompanyRoot */, opts);
  },

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $UserCenterMenuUrl(menu: UserCenterMenuEnum, opts?: AdditionalOpts): string{
    return qadd(tr(`/Account/Center#/UserCenter/${ec(menu)}`, "a65") /* FhtWebUrlRoot */, opts);
  },

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $EnterpriseCenterMenuUrl(menu: EnterpriseCenterMenuEnum, opts?: AdditionalOpts): string{
    return qadd(tr(`/Account/Center#/CompanyCenter/${ec(menu)}`, "a65") /* FhtWebUrlRoot */, opts);
  },

  $EditTemplateUrl: tr(`/Account/EditTemplate`, "a65") /* FhtWebUrlRoot */,

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $CompanyMobileUrl(companyId: number, opts?: AdditionalOpts): string{
    return qadd(tr(`/c${ec(companyId)}`, "be2") /* FhtWebMobileCompanyRoot */, opts);
  },

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $ArticleDetailUrl(articleId: number, companyId: number, opts?: AdditionalOpts): string{
    return qadd(tr(`/c${ec(companyId)}/n${ec(articleId)}.html`, "2cc") /* FhtWebCompanyRoot */, opts);
  },

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $ArticleDetailMobileUrl(articleId: number, companyId: number, opts?: AdditionalOpts): string{
    return qadd(tr(`/c${ec(companyId)}/n${ec(articleId)}.html`, "be2") /* FhtWebMobileCompanyRoot */, opts);
  },

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $TopicPostDetailUrl(topicPostId: number, opts?: AdditionalOpts): string{
    return qadd(tr(`/fuwu/${ec(topicPostId)}.html`, "bb0") /* FhtWebTopicRoot */, opts);
  },

  $TopicPostSearchUrl: tr(`/tsearch`, "bb0") /* FhtWebTopicRoot */,

  $TopicPostListUrl: tr(`/fuwu`, "bb0") /* FhtWebTopicRoot */,

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $TopicPostListByPinYinUrl(topicType: string, opts?: AdditionalOpts): string{
    return qadd(tr(`/fuwu/${ec(topicType)}`, "bb0") /* FhtWebTopicRoot */, opts);
  },

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $ProductDetail(productId: number, opts?: AdditionalOpts): string{
    return qadd(tr(`/pro${ec(productId)}.html`, "540") /* FhtWebDetailRoot */, opts);
  },

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $ProductMobileDetail(productId: number, opts?: AdditionalOpts): string{
    return qadd(tr(`/pro${ec(productId)}.html`, "546") /* FhtWebMobileUrlRoot */, opts);
  },

  $ProductListMobileUrl: tr(`/list`, "546") /* FhtWebMobileUrlRoot */,

  $ProductListUrl: tr(`/list`, "a65") /* FhtWebUrlRoot */,

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $ProductListUrl2(systemCategoryId: number, opts?: AdditionalOpts): string{
    return qadd(tr(`/list/${ec(systemCategoryId)}`, "a65") /* FhtWebUrlRoot */, opts);
  },

  $CompanySearchUrl: tr(`/csearch`, "a65") /* FhtWebUrlRoot */,

  $OfficialWebsiteUrl: tr(`/officialsite/home.html`, "a65") /* FhtWebUrlRoot */,

  $UserSearchUrl: tr(`/usearch`, "a65") /* FhtWebUrlRoot */,

  $ProductSearchUrl: tr(`/psearch`, "a65") /* FhtWebUrlRoot */,

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $ProductSearchUrl2(q: string, opts?: AdditionalOpts): string{
    return qadd(tr(`/psearch?q=${ec(q)}`, "a65") /* FhtWebUrlRoot */, opts);
  },

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $ProfileUrl(userId: number, opts?: AdditionalOpts): string{
    return qadd(tr(`/user/${ec(userId)}.html`, "a65") /* FhtWebUrlRoot */, opts);
  },

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $MembershipApplicationUrl(companyId?: number, opts?: AdditionalOpts): string{
    return qadd(tr(`/Company/MembershipApplication?companyId=${ec(companyId)}`, "a65") /* FhtWebUrlRoot */, opts);
  },

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $CompanyCertificateUrl(companyId: number, opts?: AdditionalOpts): string{
    return qadd(tr(`/certificate/${ec(companyId)}.html`, "a65") /* FhtWebUrlRoot */, opts);
  },

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $OfficialSiteProductUrl(selectedTabId?: ProductAndServiceMenuTypeEnum, opts?: AdditionalOpts): string{
    return qadd(tr(`/officialsite/${ec(selectedTabId)}.html`, "a65") /* FhtWebUrlRoot */, opts);
  },

  LoggedInUserIndexPartialUrl: `/Account/LoggedInUserIndexPartial`/* null */,

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $LoginUrl(returnUrl?: string, opts?: AdditionalOpts): string{
    return qadd(tr(`/Account/Login?returnUrl=${ec(returnUrl)}`, "a65") /* FhtWebUrlRoot */, opts);
  },

  $RegisterUrl: tr(`/Account/Register`, "a65") /* FhtWebUrlRoot */,

  $Fht1LoggedInUserIndexPartialUrl: tr(`/Account/Fht1LoggedInUserIndexPartial`, "a65") /* FhtWebUrlRoot */,

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  CompanyDetailModalUrl(companyId: number, opts?: AdditionalOpts): string{
    return qadd(`/Company/CompanyDetailModal?companyId=${ec(companyId)}`/* null */, opts);
  },

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  UserHoverCardUrl(userId: number, opts?: AdditionalOpts): string{
    return qadd(`/User/UserHoverCard?userId=${ec(userId)}`/* null */, opts);
  },

  $BrowserUnsupportedUrl: tr(`/Exception/BrowserUnsupported`, "a65") /* FhtWebUrlRoot */,

  $XdLocalStorageUrl: tr(`/Home/Xdlocalstorage`, "a65") /* FhtWebUrlRoot */,

  FileUploadUrl: `/Upload/FileUpload`/* null */,

  SearchArticleIndexUrl: `/hot/xw`/* null */,

  SearchCompanyIndexUrl: `/company`/* null */,

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $SystemCategoryId_(systemCategoryId: number, companyId: number, opts?: AdditionalOpts): string{
    return qadd(tr(`/c${ec(companyId)}/s${ec(systemCategoryId)}`, "2cc") /* FhtWebCompanyRoot */, opts);
  },

  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $CustomCategoryId_(customCategoryId: number, companyId: number, opts?: AdditionalOpts): string{
    return qadd(tr(`/c${ec(companyId)}/ct${ec(customCategoryId)}`, "2cc") /* FhtWebCompanyRoot */, opts);
  },

}
  
