import { getExpirableActionManager } from "@/expirableActionManager";
import { EXPIRY_INTERVAL_IN_SECONDS } from "@/consts";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function getProductCountUserActionManager(userId?: string) {
  return getExpirableActionManager<boolean>(
    `should_suppress_product_count_badge_alert_${userId}`,
    EXPIRY_INTERVAL_IN_SECONDS,
  );
}
