import assert from "assert";
import { ApplicationEnvEnum } from "./apis/model/ApplicationEnvEnum";
import { FhtCommonEnv } from "./apis/model/FhtCommonEnv";
import { FhtWebGlobal } from "./apis/model/FhtWebGlobal";
import { KnownPageTypeEnum } from "./apis/model/KnownPageTypeEnum";
import { IS_DEV } from "./consts";

function trimEndSlash(url: string): string {
  assert(url, "url falsy");
  if (url.endsWith("/")) {
    url = url.substr(0, url.length - 1);
  }
  return url;
}

const MD_WEB_PROXY_URL = "http://www.mobiledevelopmentfht360web.com";
const MD_CMS_PROXY_URL = "http://company.mobiledevelopmentfht360web.com";

export const WEB_PROXY_URL = trimEndSlash(process.env.WEB_PROXY_URL || MD_WEB_PROXY_URL);
export const CMS_PROXY_URL = trimEndSlash(process.env.CMS_PROXY_URL || MD_CMS_PROXY_URL);

const devCommonEnv: FhtCommonEnv = {
  AppEnv: ApplicationEnvEnum.MobileDevelopment,
  AppVersion: "0.0.0",
  DEBUG: 1,
  IsRelease: false,
  Hosts: {
    FhtWebUrlRoot: WEB_PROXY_URL,
    FhtWebCompanyRoot: CMS_PROXY_URL,
    FhtWebDetailRoot: "http://detail.mobiledevelopmentfht360web.com",
    FhtWebTopicRoot: "http://topic.mobiledevelopmentfht360web.com",
    FhtWebMobileCompanyRoot: "http://wap.mobiledevelopmentfht360web.com",
    FhtWebMobileUrlRoot: "http://m.mobiledevelopmentfht360web.com",
    FhtApiRoot: "http://192.168.1.223:8000",
  },
};

export const devFhtGlobal: FhtWebGlobal = {
  Env: devCommonEnv,
  KnownPageType: KnownPageTypeEnum.Home,
};

export const fhtGlobalEnv: FhtWebGlobal = (() => {
  if (IS_DEV) {
    console.log("WEB_PROXY_URL:", WEB_PROXY_URL);
    console.log("CMS_PROXY_URL:", CMS_PROXY_URL);
    return devFhtGlobal;
  } else {
    const g = window.fhtGlobal as FhtWebGlobal;
    assert(g, "g does not exit");
    assert(g.Env, "invalid g.Env");
    assert(g.Env.AppEnv, "invalid g.Env.AppEnv");
    return g as FhtWebGlobal;
  }
})();

assert(fhtGlobalEnv.Env.Hosts, "hosts falsy");

for (const h of Object.values(fhtGlobalEnv.Env.Hosts)) {
  assert(typeof h === "string");
  assert(!h.endsWith("/"), `host should not end with /, violation: ${h}`);
}

export const IS_PROD = fhtGlobalEnv.Env.AppEnv === ApplicationEnvEnum.Production;
