import { CompanySimpleViewModel } from "../model/CompanySimpleViewModel";
import { SearchCompanyViewModel } from "../model/SearchCompanyViewModel";
import { ModifyCompanyInputModel } from "../model/ModifyCompanyInputModel";
import { ChangeAboutUsInputModel } from "../model/ChangeAboutUsInputModel";
import { ContactInfoSimpleInputModel } from "../model/ContactInfoSimpleInputModel";
import { ChangeCompanyGeoLocationInputModel } from "../model/ChangeCompanyGeoLocationInputModel";
import { ChangeCompanyLogoUrlInputModel } from "../model/ChangeCompanyLogoUrlInputModel";
import { CompanySeoInputModel } from "../model/CompanySeoInputModel";
import { ChangeMobileTemplateIdInputModel } from "../model/ChangeMobileTemplateIdInputModel";
import { CheckIfUsedViewModel } from "../model/CheckIfUsedViewModel";
import { RegionSiteInputModel } from "../model/RegionSiteInputModel";
import { SearchSimpleCompanyViewModel } from "../model/SearchSimpleCompanyViewModel";
import { CreateCompanyInputModel } from "../model/CreateCompanyInputModel";
import { CreatedViewModel } from "../model/CreatedViewModel";
import { BoolViewModel } from "../model/BoolViewModel";
import { ExamineViewModel } from "../model/ExamineViewModel";
import { CompanyDetailViewModel } from "../model/CompanyDetailViewModel";
import { MyCompanyProfileViewModel } from "../model/MyCompanyProfileViewModel";
import { QueryResultNearCompanyViewModel } from "../model/QueryResultNearCompanyViewModel";
import { EncryptionInputModel } from "../model/EncryptionInputModel";
import { StringViewModel } from "../model/StringViewModel";
// FHT Swagger jQuery client V2
/* eslint-disable */
import { BaseApi } from "./api";
export class CompanyApiApi extends BaseApi {
    public companyApiActiveCompanyAsync = (p: {
        "args.top": number;
    }): Promise<Array<CompanySimpleViewModel>> => {
        let localVarPath = '/api/companies/activeCompanies';
        this.r(p, ["args.top",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyApiAutoCompleteCompany = (p: {
        "term": string;
    }): Promise<Array<SearchCompanyViewModel>> => {
        let localVarPath = '/api/companies/_autoComplete';
        this.r(p, ["term",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyApiCancelCompany = (): Promise<void> => {
        let localVarPath = '/api/companies/_cancel';
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, {}, bodyDict) as any;
    };
    public companyApiChangeCompany = (p: {
        "model": ModifyCompanyInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/companies/_edit';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyApiChangeCompanyAboutUs = (p: {
        "model": ChangeAboutUsInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/companies/_changecompanyaboutus';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyApiChangeCompanyContractInfo = (p: {
        "model": ContactInfoSimpleInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/companies/_changecompanycontractinfo';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyApiChangeCompanyGeoLocation = (p: {
        "model": ChangeCompanyGeoLocationInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/companies/_changecompanygeolocation';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyApiChangeCompanyLogoUrl = (p: {
        "model": ChangeCompanyLogoUrlInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/companies/_changecompanylogourl';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyApiChangeCompanyNameApplyAsync = (p: {
        "companyName": string;
    }): Promise<void> => {
        let localVarPath = '/api/companies/_changecompanynameapply';
        this.r(p, ["companyName",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyApiChangeCompanySeo = (p: {
        "model": CompanySeoInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/companies/_changecompanyseo';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyApiChangeMobileTemplate = (p: {
        "model": ChangeMobileTemplateIdInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/companies/_changemobiletemplate';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyApiCheckIfAllowedToUseCompanyName = (p: {
        "companyName": string;
    }): Promise<CheckIfUsedViewModel> => {
        let localVarPath = '/api/companies/_checkIfAllowedToUseCompanyName';
        this.r(p, ["companyName",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyApiCompanyRegionSite = (p: {
        "model": RegionSiteInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/companies/_setRegionSite';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyApiCompanyRegistrationSearchAsync = (p: {
        "q"?: string | null;
    }): Promise<Array<SearchSimpleCompanyViewModel>> => {
        let localVarPath = '/api/companies/_searchForRegistration';
        this.r(p, []);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyApiCreateCompany = (p: {
        "company": CreateCompanyInputModel;
    }): Promise<CreatedViewModel> => {
        let localVarPath = '/api/companies/_create';
        this.r(p, ["company",]);
        let bodyDict = p["company"];
        const _p = p as any;
        delete _p["company"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyApiDisableShopsAsync = (): Promise<BoolViewModel> => {
        let localVarPath = '/api/companies/_disableShops';
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, {}, bodyDict) as any;
    };
    public companyApiEnableShopsAsync = (): Promise<BoolViewModel> => {
        let localVarPath = '/api/companies/_enableShops';
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, {}, bodyDict) as any;
    };
    public companyApiExamineSite = (): Promise<ExamineViewModel> => {
        let localVarPath = '/api/companies/_examinesite';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public companyApiGetCompany = (p: {
        "companyId": number;
    }): Promise<CompanyDetailViewModel> => {
        let localVarPath = '/api/companies/{companyId}'
            .replace('{' + 'companyId' + '}', String(p["companyId"]));
        this.r(p, ["companyId",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyApiGetCompanyForModify = (): Promise<ModifyCompanyInputModel> => {
        let localVarPath = '/api/companies/_get';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public companyApiGetCompanySeo = (): Promise<CompanySeoInputModel> => {
        let localVarPath = '/api/companies/_getcompanyseo';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public companyApiGetMyCompany = (): Promise<MyCompanyProfileViewModel> => {
        let localVarPath = '/api/companies/mycompany';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public companyApiGetNearbyCompanies = (p: {
        "args.currPage": number;
        "args.limit": number;
        "longitude"?: number | null;
        "latitude"?: number | null;
        "realIp"?: string | null;
    }): Promise<QueryResultNearCompanyViewModel> => {
        let localVarPath = '/api/companies/nearbyCompanies';
        this.r(p, ["args.currPage", "args.limit",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyApiGetNewlyRegisteredEnterprise = (p: {
        "args.top": number;
    }): Promise<Array<CompanySimpleViewModel>> => {
        let localVarPath = '/api/companies/newlyRegisteredCompanies';
        this.r(p, ["args.top",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyApiGetPhoneNumber = (p: {
        "model": EncryptionInputModel;
    }): Promise<StringViewModel> => {
        let localVarPath = '/api/companies/_getPhoneNumber';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyApiGetStatCompanyIdsAsync = (p: {
        "args.limit": number;
        "args.isMember"?: boolean | null;
        "args.startTime"?: Date | null;
        "args.endTime"?: Date | null;
        "args.startCompanyId"?: number | null;
        "args.endCompanyId"?: number | null;
    }): Promise<Array<number>> => {
        let localVarPath = '/api/companies/_getstatcompanyids';
        this.r(p, ["args.limit",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyApiSetRegionSiteShow = (p: {
        "regionSiteEnabled": boolean;
    }): Promise<void> => {
        let localVarPath = '/api/companies/_setRegionSiteShow';
        this.r(p, ["regionSiteEnabled",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyApiSetShowMemberInShops = (p: {
        "isShow": boolean;
    }): Promise<void> => {
        let localVarPath = '/api/companies/_setshowmemberinshops';
        this.r(p, ["isShow",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyApiToggleShopStatus = (p: {
        "companyId": number;
    }): Promise<BoolViewModel> => {
        let localVarPath = '/api/companies/shopStatus/_toggle/{companyId}'
            .replace('{' + 'companyId' + '}', String(p["companyId"]));
        this.r(p, ["companyId",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
}
