// FHT Swagger jQuery client V2
/**
 * FHT.Core.Web.Models.KnownPageType, FHT.Core.Web, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 */
/**
* FHT.Core.Web.Models.KnownPageType, FHT.Core.Web, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
*/
// mark 1 model enum
export enum KnownPageTypeEnum {
    Home = "Home",
    ProductList = "ProductList",
    ProductDetail = "ProductDetail",
    TopicPostList = "TopicPostList",
    TopicPostDetail = "TopicPostDetail",
    UserDetail = "UserDetail",
    Account = "Account",
    OfficialSite = "OfficialSite",
    App = "App",
    Membership = "Membership",
    EditTemplate = "EditTemplate",
    Center = "Center",
    ProductSearch = "ProductSearch",
    TopicPostSearch = "TopicPostSearch",
    UserSearch = "UserSearch",
    CompanySearch = "CompanySearch"
}
