// FHT Swagger jQuery client V2
/**
 * FHT.ApplicationEnv, FHT.ApplicationEnvironment, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 */
/**
* FHT.ApplicationEnv, FHT.ApplicationEnvironment, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
*/
// mark 1 model enum
export enum ApplicationEnvEnum {
    Local = "Local",
    Development = "Development",
    MobileDevelopment = "MobileDevelopment",
    Staging = "Staging",
    Production = "Production"
}
