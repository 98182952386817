import { ApplyCompanyMemberRequestInputModel } from "../model/ApplyCompanyMemberRequestInputModel";
import { CreatedViewModel } from "../model/CreatedViewModel";
import { StringViewModel } from "../model/StringViewModel";
import { CompanyMemberViewModel } from "../model/CompanyMemberViewModel";
import { CompanyMemberApplyRequestViewModel } from "../model/CompanyMemberApplyRequestViewModel";
import { CursorPaginationResultCompanyMemberApplyRequestViewModel } from "../model/CursorPaginationResultCompanyMemberApplyRequestViewModel";
import { UnreadCountViewModel } from "../model/UnreadCountViewModel";
import { CompanyMemberInviteRequestViewModel } from "../model/CompanyMemberInviteRequestViewModel";
import { CursorPaginationResultCompanyMemberInviteRequestViewModel } from "../model/CursorPaginationResultCompanyMemberInviteRequestViewModel";
import { InvitationByAccountInputModel } from "../model/InvitationByAccountInputModel";
import { UserDetailedViewModel } from "../model/UserDetailedViewModel";
import { InviteCompanyMemberRequestInputModel } from "../model/InviteCompanyMemberRequestInputModel";
import { ActionableBatchInputModel } from "../model/ActionableBatchInputModel";
// FHT Swagger jQuery client V2
/* eslint-disable */
import { BaseApi } from "./api";
export class CompanyMemberApiApi extends BaseApi {
    public companyMemberApiAcceptApplyRequest = (p: {
        "applyRequestId": number;
    }): Promise<void> => {
        let localVarPath = '/api/companymembers/_acceptApplyRequest/{applyRequestId}'
            .replace('{' + 'applyRequestId' + '}', String(p["applyRequestId"]));
        this.r(p, ["applyRequestId",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiAcceptInviteRequest = (p: {
        "inviteRequestId": number;
    }): Promise<void> => {
        let localVarPath = '/api/companymembers/_acceptInviteRequest/{inviteRequestId}'
            .replace('{' + 'inviteRequestId' + '}', String(p["inviteRequestId"]));
        this.r(p, ["inviteRequestId",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiAnalysisInvitationCode = (p: {
        "code": string;
    }): Promise<void> => {
        let localVarPath = '/api/companymembers/_analysisinvitationcode';
        this.r(p, ["code",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiApply = (p: {
        "model": ApplyCompanyMemberRequestInputModel;
    }): Promise<CreatedViewModel> => {
        let localVarPath = '/api/companymembers/_apply';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiAssignSubAdmin = (p: {
        "memberId": number;
    }): Promise<void> => {
        let localVarPath = '/api/companymembers/_assignSubAdmin/{memberId}'
            .replace('{' + 'memberId' + '}', String(p["memberId"]));
        this.r(p, ["memberId",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiCancelApplyRequest = (p: {
        "applyRequestId": number;
    }): Promise<void> => {
        let localVarPath = '/api/companymembers/_cancelApplyRequest/{applyRequestId}'
            .replace('{' + 'applyRequestId' + '}', String(p["applyRequestId"]));
        this.r(p, ["applyRequestId",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiCancelInviteRequest = (p: {
        "inviteRequestId": number;
    }): Promise<void> => {
        let localVarPath = '/api/companymembers/_cancelInviteRequest/{inviteRequestId}'
            .replace('{' + 'inviteRequestId' + '}', String(p["inviteRequestId"]));
        this.r(p, ["inviteRequestId",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiGenInvitationCode = (): Promise<StringViewModel> => {
        let localVarPath = '/api/companymembers/_geninvitationcode';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public companyMemberApiGetCompanyAllMembersByCompanyId = (p: {
        "companyId": number;
    }): Promise<Array<CompanyMemberViewModel>> => {
        let localVarPath = '/api/companies/{companyId}/members'
            .replace('{' + 'companyId' + '}', String(p["companyId"]));
        this.r(p, ["companyId",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiGetCompanyMembers = (): Promise<Array<CompanyMemberViewModel>> => {
        let localVarPath = '/api/companymembers';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public companyMemberApiGetCustomerServices = (): Promise<Array<CompanyMemberViewModel>> => {
        let localVarPath = '/api/companymembers/customerServices';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public companyMemberApiGetIncomingCompanyMemberApplyRequest = (p: {
        "applyRequestId": number;
    }): Promise<CompanyMemberApplyRequestViewModel> => {
        let localVarPath = '/api/incomingCompanyMemberApplyRequests/{applyRequestId}'
            .replace('{' + 'applyRequestId' + '}', String(p["applyRequestId"]));
        this.r(p, ["applyRequestId",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiGetIncomingCompanyMemberApplyRequests = (p: {
        "args.limit": number;
        "args.cursor"?: number | null;
    }): Promise<CursorPaginationResultCompanyMemberApplyRequestViewModel> => {
        let localVarPath = '/api/incomingCompanyMemberApplyRequests';
        this.r(p, ["args.limit",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiGetIncomingCompanyMemberApplyRequestsCount = (p: {
        "args.lastId"?: number | null;
    }): Promise<UnreadCountViewModel> => {
        let localVarPath = '/api/incomingCompanyMemberApplyRequests/unreadcount';
        this.r(p, []);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiGetIncomingCompanyMemberInviteRequest = (p: {
        "inviteRequestId": number;
    }): Promise<CompanyMemberInviteRequestViewModel> => {
        let localVarPath = '/api/incomingCompanyMemberInviteRequests/{inviteRequestId}'
            .replace('{' + 'inviteRequestId' + '}', String(p["inviteRequestId"]));
        this.r(p, ["inviteRequestId",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiGetIncomingCompanyMemberInviteRequests = (p: {
        "args.limit": number;
        "args.cursor"?: number | null;
    }): Promise<CursorPaginationResultCompanyMemberInviteRequestViewModel> => {
        let localVarPath = '/api/incomingCompanyMemberInviteRequests';
        this.r(p, ["args.limit",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiGetIncomingCompanyMemberInviteRequestsCount = (p: {
        "args.lastId"?: number | null;
    }): Promise<UnreadCountViewModel> => {
        let localVarPath = '/api/incomingCompanyMemberInviteRequests/unreadcount';
        this.r(p, []);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiGetMyCompanyMember = (): Promise<CompanyMemberViewModel> => {
        let localVarPath = '/api/companymembers/myCompanyMember';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public companyMemberApiGetOutgoingCompanyMemberApplyRequest = (p: {
        "applyRequestId": number;
    }): Promise<CompanyMemberApplyRequestViewModel> => {
        let localVarPath = '/api/outgoingCompanyMemberApplyRequests/{applyRequestId}'
            .replace('{' + 'applyRequestId' + '}', String(p["applyRequestId"]));
        this.r(p, ["applyRequestId",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiGetOutgoingCompanyMemberApplyRequestUnreadCount = (p: {
        "args.lastId"?: number | null;
    }): Promise<UnreadCountViewModel> => {
        let localVarPath = '/api/outgoingCompanyMemberApplyRequests/unreadcount';
        this.r(p, []);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiGetOutgoingCompanyMemberApplyRequests = (p: {
        "args.limit": number;
        "args.cursor"?: number | null;
    }): Promise<CursorPaginationResultCompanyMemberApplyRequestViewModel> => {
        let localVarPath = '/api/outgoingCompanyMemberApplyRequests';
        this.r(p, ["args.limit",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiGetOutgoingCompanyMemberInviteRequest = (p: {
        "inviteRequestId": number;
    }): Promise<CompanyMemberInviteRequestViewModel> => {
        let localVarPath = '/api/outgoingCompanyMemberInviteRequests/{inviteRequestId}'
            .replace('{' + 'inviteRequestId' + '}', String(p["inviteRequestId"]));
        this.r(p, ["inviteRequestId",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiGetOutgoingCompanyMemberInviteRequests = (p: {
        "args.limit": number;
        "args.cursor"?: number | null;
    }): Promise<CursorPaginationResultCompanyMemberInviteRequestViewModel> => {
        let localVarPath = '/api/outgoingCompanyMemberInviteRequests';
        this.r(p, ["args.limit",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiInvitationByAccountAsync = (p: {
        "model": InvitationByAccountInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/companymembers/_invitationbyaccount';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiInvitationUserAsync = (p: {
        "code": string;
    }): Promise<UserDetailedViewModel> => {
        let localVarPath = '/api/companymembers/_invitationuser';
        this.r(p, ["code",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiInvite = (p: {
        "model": InviteCompanyMemberRequestInputModel;
    }): Promise<CreatedViewModel> => {
        let localVarPath = '/api/companymembers/_invite';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiKickMember = (p: {
        "memberId": number;
    }): Promise<void> => {
        let localVarPath = '/api/companymembers/_kickMember/{memberId}'
            .replace('{' + 'memberId' + '}', String(p["memberId"]));
        this.r(p, ["memberId",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiKickSubAdmin = (p: {
        "memberId": number;
    }): Promise<void> => {
        let localVarPath = '/api/companymembers/_kickSubAdmin/{memberId}'
            .replace('{' + 'memberId' + '}', String(p["memberId"]));
        this.r(p, ["memberId",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiLeave = (): Promise<void> => {
        let localVarPath = '/api/companymembers/_leave';
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, {}, bodyDict) as any;
    };
    public companyMemberApiRejectApplyRequest = (p: {
        "applyRequestId": number;
    }): Promise<void> => {
        let localVarPath = '/api/companymembers/_rejectApplyRequest/{applyRequestId}'
            .replace('{' + 'applyRequestId' + '}', String(p["applyRequestId"]));
        this.r(p, ["applyRequestId",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiRejectInviteRequest = (p: {
        "inviteRequestId": number;
    }): Promise<void> => {
        let localVarPath = '/api/companymembers/_rejectInviteRequest/{inviteRequestId}'
            .replace('{' + 'inviteRequestId' + '}', String(p["inviteRequestId"]));
        this.r(p, ["inviteRequestId",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiSetCustomerServices = (p: {
        "model": ActionableBatchInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/companymembers/customerServices';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public companyMemberApiTransferMainAdmin = (p: {
        "memberId": number;
    }): Promise<void> => {
        let localVarPath = '/api/companymembers/_transferMainAdmin/{memberId}'
            .replace('{' + 'memberId' + '}', String(p["memberId"]));
        this.r(p, ["memberId",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
}
