import Cookies from "js-cookie";
import assert from "assert";
import { ElTableColumnFilterPair, ElTableFormFilterPair } from "el-search-table-pagination";
import { fhtGlobalEnv } from "./env";
import { Message, MessageBox } from "element-ui";
import { MessageType } from "element-ui/types/message";
import { MessageBoxInputData, MessageBoxCloseAction, MessageBoxData } from "element-ui/types/message-box";
import { AlertType } from "element-ui/types/alert";
import { IS_DEV } from "./consts";
import nameof from "ts-nameof.macro";
import { qadd, UrlHelperExtensions } from "./url_helpers";

export function newContainer(): HTMLElement {
  const d = document.createElement("div");
  // eslint-disable-next-line compat/compat
  document.body.appendChild(d);
  return d;
}

export function getAuthHash(): string {
  const authCookie = Cookies.get("FhtAuthCookie");
  if (!authCookie) return fhtGlobalEnv.Env.AppVersion;
  const authCookieString = authCookie.substring(0, 16);
  return fhtGlobalEnv.Env.AppVersion + "_" + authCookieString;
}

const FhtFirstOldVipTipCookieKey = "FhtFirstOldVipTipCookie";
export function getFirstTipOldVip(): boolean {
  const fhtFirstOldVipTip = Cookies.get(FhtFirstOldVipTipCookieKey);
  if (!fhtFirstOldVipTip) {
    Cookies.set(FhtFirstOldVipTipCookieKey, "1");
    return true;
  }
  return false;
}

export function getIsFhtNoShopChecked(companyId: number): boolean {
  const FhtNoShopCheckCookieKey = "FhtNoShopCheckCookieKey" + companyId;
  const fhtNoShopCheck = Cookies.get(FhtNoShopCheckCookieKey);
  if (!fhtNoShopCheck) {
    const inFifteenMinutes = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
    Cookies.set(FhtNoShopCheckCookieKey, "1", { expires: inFifteenMinutes });
    return true;
  }
  return false;
}

export function formatError(err: unknown): string {
  const error = err as Error;
  if (error.message) {
    return error.message;
  }
  return "unknown error";
}

// smart url cache invalidation scheme
export function versionUrl(url: string): string {
  const version = IS_DEV ? new Date().getTime().toString() : fhtGlobalEnv.Env.AppVersion;
  return qadd(url, { v: version });
}

export function removeHostFromUrl(url: string): string {
  //courtesy of https://stackoverflow.com/questions/11550790/remove-hostname-and-port-from-url-using-regular-expression
  return url.replace(/^[a-zA-Z]{3,5}:\/{2}[a-zA-Z0-9_.:-]+\//, "/"); // http or https
}

export function toPromise<T>($d: JQueryDeferred<T> | JQueryPromise<T>): Promise<T> {
  assert($d, "$d falsy");
  assert($d.state, "not jquery promise?");
  return new Promise<T>((res, rej) => {
    void $d.done((d) => res(d!)).fail(rej);
  });
}

export const nextId = (() => {
  let _id = 0;
  return () => {
    return ++_id;
  };
})();

export function wait(ms: number): Promise<void> {
  return new Promise((res) => {
    setTimeout(res, ms);
  });
}

export function retriedWaitFor<T>(
  evaluator: () => T,
  tag: string,
  checkIntervalMs = 100,
  timeoutMs = 5000,
): Promise<T> {
  return new Promise<T>((res, rej) => {
    const value = evaluator();
    if (value) {
      res(value);
    } else {
      const startTime = new Date().getTime();
      const tid = setInterval(() => {
        const valueAgain = evaluator();
        if (IS_DEV) {
          console.log(`retried wait for "${tag}"`, valueAgain);
        }
        if (valueAgain) {
          clearInterval(tid);
          res(valueAgain);
        } else {
          const timedout = new Date().getTime() - startTime > timeoutMs;
          if (timedout) {
            clearInterval(tid);
            rej(new Error(`wait for evaluator: ${tag} timed out after ${timeoutMs} ms`));
          }
        }
      }, checkIntervalMs);
    }
  });
}

export async function retriedWaitForPromise<T>(
  evaluator: () => Promise<T>,
  checkIntervalMs: number,
  timeoutMs: number,
): Promise<T> {
  const startTime = new Date().getTime();
  while (new Date().getTime() - startTime < timeoutMs) {
    const value = await evaluator();
    if (value) return value;
    await wait(checkIntervalMs);
  }
  throw new Error(`wait for evaluator promise timed out after ${timeoutMs} ms`);
}

class PersistentStorage implements IFhtStorage {
  private readonly _s: Promise<IFhtStorage> | undefined;
  constructor() {
    if (!IS_DEV) {
      this._s = retriedWaitFor(() => window._FHT_STORAGE_, nameof(window._FHT_STORAGE_), 200, 5000);
    }
  }

  async removeItem(key: string): Promise<void> {
    if (!this._s) {
      // eslint-disable-next-line no-restricted-globals
      localStorage.removeItem(key);
      return;
    }
    const s = await this._s;
    await s.removeItem(key);
  }

  async getItem(key: string): Promise<string | undefined> {
    if (!this._s) {
      // eslint-disable-next-line no-restricted-globals
      return Promise.resolve(localStorage.getItem(key) || undefined);
    }
    return (await this._s).getItem(key);
  }

  async setItem(key: string, value: string): Promise<string> {
    if (!this._s) {
      // eslint-disable-next-line no-restricted-globals
      localStorage.setItem(key, value);
      return Promise.resolve(value);
    }
    return (await this._s).setItem(key, value);
  }
}

export const persistentStorage = new PersistentStorage();

export function makeTableColumnFilter<TEnum extends string>(
  enumEntries: [string, TEnum][],
  describer: (e: TEnum) => string,
): ElTableColumnFilterPair[] {
  return enumEntries.map(([k, v]) => ({
    text: describer(v),
    value: v as string,
  }));
}

type NewType = ElTableFormFilterPair;

export function makeTableFormColumnFilter<TEnum extends string>(
  enumEntries: [string, TEnum][],
  describer: (e: TEnum) => string,
): NewType[] {
  return enumEntries.map(([k, v]) => ({
    label: describer(v),
    value: v as string,
  }));
}

//ElOption is not suitable
export interface ISelectable {
  label: string;
  value: string;
}

export function makeSelectable<Enum extends string>(
  enumEntries: [string, Enum][],
  describer: (e: Enum) => string,
): ISelectable[] {
  return enumEntries.map(([k, v]) => ({
    label: describer(v),
    value: v as string,
  }));
}

// courtesy of https://stackoverflow.com/questions/48071279/how-to-specify-non-reactive-instance-property-in-vue-js-using-typescript
export function withNonReactive<TData>(data: TData) {
  return function <TNonReactive>(unTypedData: TNonReactive): TData & TNonReactive {
    assert(unTypedData, "unTypedData falsy");
    return data as TData & TNonReactive;
  };
}

export function assertNever(value: never, msg?: string): never {
  throw Error(`Unexpected never value '${value}': ${msg}`);
}

export async function confirmInfo(
  text: string,
  type: MessageType = "success",
): Promise<MessageBoxInputData | MessageBoxCloseAction> {
  try {
    // eslint-disable-next-line no-restricted-syntax
    return await MessageBox.alert(text, "提示", {
      confirmButtonText: "确定",
      type: type,
    });
  } catch (err) {
    console.log("您取消了:", text);
    return "cancel";
  }
}

// eslint-disable-next-line no-restricted-globals
const isDEBUG = localStorage.getItem("DEBUG");

export function debugToast(message: string): void {
  if (isDEBUG) {
    Message.warning("DEBUG: " + message);
  }
}

export async function confirmAlert(
  text = "确定执行此操作吗？",
  type: MessageType = "warning",
): Promise<MessageBoxInputData | MessageBoxCloseAction> {
  try {
    // eslint-disable-next-line no-restricted-syntax
    return await MessageBox.confirm(text, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: type,
    });
  } catch (err) {
    console.log("您取消了:", text);
    return "cancel";
  }
}

export async function confirmAlertHeightened(
  text = "确定执行此操作吗？",
  type: MessageType = "warning",
): Promise<MessageBoxInputData | MessageBoxCloseAction> {
  try {
    // eslint-disable-next-line no-restricted-syntax
    return await MessageBox.confirm(text, "提示", {
      cancelButtonText: "取消",
      confirmButtonText: "确定",
      type: type,
      customClass: "heightened-warning",
    });
  } catch (err) {
    console.log("您取消了:", text);
    return "cancel";
  }
}

export async function fhtAlert(
  text: string,
  type: AlertType = "warning",
  showCancelButton = false,
): Promise<MessageBoxData> {
  try {
    // eslint-disable-next-line no-restricted-syntax
    return await MessageBox.alert(text, {
      type: type,
      showCancelButton,
      showConfirmButton: true,
    });
  } catch (err) {
    return "cancel";
  }
}

export const requestAnimationFrame = (function () {
  return (
    window.requestAnimationFrame ||
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.mozRequestAnimationFrame ||
    function (callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();

//trigger click in response to element-ui's src\utils\clickoutside.js

export function triggerClick(elm: HTMLElement): void {
  const down = document.createEvent("MouseEvent");
  down.initEvent("mousedown", true);
  const up = document.createEvent("MouseEvent");
  up.initEvent("mouseup", true);
  elm.dispatchEvent(down);
  elm.dispatchEvent(up);
}

function two(s: string | number) {
  return s.toString().padStart(2, "0");
}

export function fromNow(d: Date, now = new Date()): string {
  const elapsedMs = now.getTime() - d.getTime();
  const elapsedSec = Math.floor(elapsedMs / 1000);
  const ONE_MINUTE = 1 * 60;
  const ONE_HOUR = ONE_MINUTE * 60;
  const ONE_DAY = ONE_HOUR * 24;
  const ONE_YEAR = ONE_DAY * 365;
  if (elapsedSec < ONE_MINUTE) {
    return "刚刚";
  } else if (elapsedSec < ONE_HOUR) {
    const elapsedMin = Math.floor(elapsedSec / ONE_MINUTE);
    return `${elapsedMin}分钟前`;
  } else if (elapsedSec < ONE_DAY) {
    return `${two(d.getHours())}:${two(d.getMinutes())}`;
  } else if (elapsedSec < ONE_YEAR) {
    return `${two(d.getMonth() + 1)}/${two(d.getDate())}`;
  } else {
    return `${d.getFullYear()}/${two(d.getMonth() + 1)}/${two(d.getDate())}`;
  }
}
