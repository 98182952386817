export enum AdditionalPage {
  EditTemplate = "EditTemplate",
  LayoutFeatures = "LayoutFeatures",
  CmsSettingAdmin = "CmsSettingAdmin",
  Login = "Login",
  LoginComplex = "LoginComplex",
  FindPassword = "FindPassword",
  BindExternalAccount = "BindExternalAccount",
  Register = "Register",
}
