// FHT Swagger jQuery client V2
/**
 * FHT.Common.Types.FhtConstants, FHT.Common.Types, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null
 */
/**
* FHT.Common.Types.FhtConstants, FHT.Common.Types, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null
*/
// mark 1 model enum
export enum FhtConstantsEnum {
    CzCustmerServiceHotline = "CzCustmerServiceHotline",
    CzCustmerServiceHotlineExtra = "CzCustmerServiceHotlineExtra",
    NjCustmerServiceHotline = "NjCustmerServiceHotline",
    NjAddress = "NjAddress",
    CzAddress = "CzAddress",
    NjCompanyName = "NjCompanyName",
    CzCompanyName = "CzCompanyName",
    NjPostCode = "NjPostCode",
    CzPostCode = "CzPostCode",
    CzCustmerServiceHotlineSecondExtra = "CzCustmerServiceHotlineSecondExtra"
}
