
import { FhtWebCompanyHostTypeEnum } from "./FhtWebCompanyHostTypeEnum"
export function describeFhtWebCompanyHostTypeEnum(e: FhtWebCompanyHostTypeEnum): string{
    switch(e){
        case FhtWebCompanyHostTypeEnum.Local:
            return "http://localhost:5002";
        case FhtWebCompanyHostTypeEnum.Development:
            return "http://company.developmentfht360web.com";
        case FhtWebCompanyHostTypeEnum.MobileDevelopment:
            return "http://company.mobiledevelopmentfht360web.com";
        case FhtWebCompanyHostTypeEnum.Staging:
            return "http://company.stagingfht360web.com";
        case FhtWebCompanyHostTypeEnum.Production:
            return "https://company.fht360.com";
        default:
            throw new Error(`${e} is an invalid FhtWebCompanyHostTypeEnum`);
    }
}
		