import assert from "assert";
import Router, { Route, RouteConfig } from "vue-router";
import { IS_DEV } from "@/consts";
import { fhtAlert, retriedWaitFor, confirmAlert } from "@/utils";
import { flatMap } from "lodash";
import { ICenterRouteMetaModel } from "@/pages/Center/RouteMetaModel";
import { basicAccountInfoManager } from "@/pages/LayoutFeatures/Account/basicAccountInfoManager";
import { UrlHelperExtensions } from "@/url_helpers";
import { UserApiApi } from "./apis/api/UserApiApi";
import { defaultHeaders } from "./apis/api/api";
import { EligibilityStatusEnum } from "./apis/model/EligibilityStatusEnum";
import { EnterpriseCenterMenuEnum } from "./apis/model/EnterpriseCenterMenuEnum";
import { UserCenterMenuEnum } from "./apis/model/UserCenterMenuEnum";

export interface IAuthMetaModel {
  loginRequired: boolean;
}

export function mapRouteToProps(route: Route): { [x: string]: string | (string | null)[] } {
  const props = { ...route.query, ...route.params };
  return props;
}

function configureRouter(router: Router): void {
  assert(router, "router falsy");
  router.beforeEach(async (to, from, next) => {
    const meta = to.meta as ICenterRouteMetaModel | undefined;
    if (!IS_DEV) {
      if (meta?.loginRequired) {
        const info = await basicAccountInfoManager.getBasicAccountInfo();
        if (!info.UserId) {
          console.log("Not logged in, redirect");
          location.href = UrlHelperExtensions.$LoginUrl(location.href);
        }
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      if (meta?.eligiblity) {
        const userApiApi = new UserApiApi();
        if (IS_DEV) {
          await retriedWaitFor(() => defaultHeaders.Authorization, "auth_token", 100, 10000);
        }
        const res = await userApiApi.userApiEligibility({
          model: { Eligibility: meta.eligiblity },
        });
        if (res.Status === EligibilityStatusEnum.Error) {
          if (res.RedirectUrl) {
            const choice = await confirmAlert(res.Reason);
            if (choice === "confirm") {
              location.href = res.RedirectUrl;
            } else {
              window.history.back();
            }
          } else {
            await fhtAlert(res.Reason);
            const ec = EnterpriseCenterMenuEnum[to.name as EnterpriseCenterMenuEnum];
            const uc = UserCenterMenuEnum[to.name as UserCenterMenuEnum];
            if (ec) {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              router.push({ name: EnterpriseCenterMenuEnum.EnterpriseHome });
            } else if (uc && uc !== UserCenterMenuEnum.UserHome) {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              router.push({ name: UserCenterMenuEnum.UserHome });
            } else {
              console.error("not in ec or uc???", to);
            }
          }
        } else if (res.Status === EligibilityStatusEnum.Warn) {
          const choice = await fhtAlert(res.Reason, "warning", true);
          if (choice === "confirm" && res.RedirectUrl) {
            location.href = res.RedirectUrl;
          }
        }
      }
    })();
    next();
  });
}

export function makeRouter(...configs: RouteConfig[][]): Router {
  const r = new Router({
    mode: "hash",
    base: process.env.BASE_URL,
    routes: flatMap(configs, (c) => c),
  });
  configureRouter(r);
  return r;
}
