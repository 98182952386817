import { CreateSmsVerificationCodeInputModel } from "../model/CreateSmsVerificationCodeInputModel";
import { EligibilityInputModel } from "../model/EligibilityInputModel";
import { EligibilityViewModel } from "../model/EligibilityViewModel";
import { UserSimpleViewModel } from "../model/UserSimpleViewModel";
import { UserDetailedViewModel } from "../model/UserDetailedViewModel";
// FHT Swagger jQuery client V2
/* eslint-disable */
import { BaseApi } from "./api";
export class UserApiApi extends BaseApi {
    public userApiChangeFht1AccountVerifyCode = (p: {
        "model": CreateSmsVerificationCodeInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/verifyCodes/_changeFht1AccountVerifyCodecreate';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public userApiEligibility = (p: {
        "model": EligibilityInputModel;
    }): Promise<EligibilityViewModel> => {
        let localVarPath = '/api/users/_eligibility';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public userApiGetUserByMobileAsync = (p: {
        "mobile": string;
    }): Promise<UserSimpleViewModel> => {
        let localVarPath = '/api/users/getUserByMobile';
        this.r(p, ["mobile",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public userApiUserDetail = (p: {
        "userId": number;
    }): Promise<UserDetailedViewModel> => {
        let localVarPath = '/api/users/{userId}'
            .replace('{' + 'userId' + '}', String(p["userId"]));
        this.r(p, ["userId",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
}
