// FHT Swagger jQuery client V2
/**
 * FHT.Core.Web.Models.VueFunctionality, FHT.Core.Web, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 */
/**
* FHT.Core.Web.Models.VueFunctionality, FHT.Core.Web, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
*/
// mark 1 model enum
export enum VueFunctionalityEnum {
    Center = "Center",
    Report = "Report",
    Feedback = "Feedback",
    EditTemplate = "EditTemplate",
    LayoutNotifications = "LayoutNotifications",
    CreateTopicPost = "CreateTopicPost",
    Login = "Login",
    FindPassword = "FindPassword",
    Register = "Register",
    AgencyApplicationFormModal = "AgencyApplicationFormModal",
    MemberApplicationFormModal = "MemberApplicationFormModal",
    CmsSettingAdmin = "CmsSettingAdmin",
    BindExternalAccount = "BindExternalAccount"
}
