import { fhtGlobalEnv } from "./env";
import assert from "assert";
import { IS_DEV } from "./consts";

// ################### FILE GENERATED ONLY IF THIS FILE DOES NOT ALREADY EXIST #######
// TO HAVE THE FILE GENERATED AGAIN, DELETE THIS AND RUN url_gen.js
export interface AdditionalOpts {
  stat_ctx?: "ExampleCtx1" | "ExampleCtx2";
}

export function getActualHost(hostTag: string): string {
  if (IS_DEV) return location.origin;
  const hosts = fhtGlobalEnv.Env.Hosts as unknown as { [k: string]: string };
  const h = hosts[hostTag];
  assert(typeof h !== "undefined", `failed to find hostTag: ${hostTag}`);
  return h;
}
