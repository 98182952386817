import Vue from "vue";
import "@/styles/element-theme.scss";
import { basicAccountInfoManager } from "./LayoutFeatures/Account/basicAccountInfoManager";
import store from "@/store";
import assert from "assert";
import "url-polyfill";
import { fhtGlobalEnv } from "@/env";
import { centerRouteConfigs } from "./Center/centerRouteConfigs";
import { makeRouter } from "@/routerUtils";
import { editTemplateRouteConfigs } from "./EditTemplate/editTemplateRouteConfigs";
import { ApplicationEnvEnum } from "@/apis/model/ApplicationEnvEnum";
import { VueFunctionalityEnum } from "@/apis/model/VueFunctionalityEnum";

void (async () => {
  if (
    fhtGlobalEnv.Env.AppEnv === ApplicationEnvEnum.Development ||
    fhtGlobalEnv.Env.AppEnv === ApplicationEnvEnum.MobileDevelopment
  ) {
    await import(/* webpackChunkName: "debug"*/ "./debug");
    console.log("Debug Installed for non-production env: " + fhtGlobalEnv.Env.AppEnv);
  }
})();

Vue.config.productionTip = false;
Vue.config.devtools = !!process.env.DEVTOOL;
// console.log("DEV TOOL OPEN?", Vue.config.devtools, Vue.config);

Vue.config.productionTip = false;

// eslint-disable-next-line fht-eslint-rules/no-property-assignment-to-window
window._BASIC_ACCOUNT_INFO_MANAGER_ = basicAccountInfoManager;

const vueFunctionalityLoaders: { [k in VueFunctionalityEnum]: (el: HTMLElement) => Promise<void> } = {
  [VueFunctionalityEnum.Center]: async (el) => {
    const router = makeRouter(centerRouteConfigs);
    const RouterViewWrapper = (await import("@/components/RouterViewWrapper.vue")).default;
    new Vue({
      router,
      store,
      render(h) {
        return h(RouterViewWrapper);
      },
    }).$mount(el);
  },
  [VueFunctionalityEnum.CmsSettingAdmin]: async (el) => {
    const CmsSettingAdmin = (await import("./CmsSettingAdmin/CmsSettingAdmin.vue")).default;
    new Vue({
      store,
      render(h) {
        return h(CmsSettingAdmin);
      },
    }).$mount(el);
  },
  [VueFunctionalityEnum.EditTemplate]: async (el) => {
    const router = makeRouter(editTemplateRouteConfigs);
    const EditTemplate = (await import("./EditTemplate/EditTemplate.vue")).default;
    new Vue({
      router,
      store,
      render(h) {
        return h(EditTemplate);
      },
    }).$mount(el);
  },
  [VueFunctionalityEnum.Login]: async (el) => {
    const Login = (await import("./LoginAndRegister/LoginComplex.vue")).default;
    new Vue({
      store,
      render(h) {
        return h(Login);
      },
    }).$mount(el);
  },
  [VueFunctionalityEnum.BindExternalAccount]: async (el) => {
    const Login = (await import("./LoginAndRegister/BindExternalAccount.vue")).default;
    new Vue({
      store,
      render(h) {
        return h(Login);
      },
    }).$mount(el);
  },
  [VueFunctionalityEnum.FindPassword]: async (el) => {
    const FindPassword = (await import("./LoginAndRegister/FindPassword.vue")).default;
    new Vue({
      store,
      render(h) {
        return h(FindPassword);
      },
    }).$mount(el);
  },
  [VueFunctionalityEnum.Register]: async (el) => {
    const Register = (await import("./LoginAndRegister/Register.vue")).default;
    new Vue({
      store,
      render(h) {
        return h(Register);
      },
    }).$mount(el);
  },
  [VueFunctionalityEnum.Report]: async (el) => {
    const { setUpReportUser, setUpReportTopicPost, setUpReportProduct, setUpReportCompany } = await import(
      "./LayoutFeatures/Report/setupReports"
    );
    setUpReportUser();
    setUpReportTopicPost();
    setUpReportProduct();
    setUpReportCompany();

    await import(
      "@/pages/Center/EnterpriseCenter/CompanyManagement/MemberManagement/installMemberInvitationAcceptModal"
    );
  },
  [VueFunctionalityEnum.Feedback]: async (el) => {
    const { setUpFeedback } = await import("./LayoutFeatures/Feedback/setupFeedback");
    setUpFeedback();
  },
  [VueFunctionalityEnum.CreateTopicPost]: async (el) => {
    const { setUpTopicPost } = await import("./LayoutFeatures/TopicPost/setupTopicPost");
    setUpTopicPost();
  },
  [VueFunctionalityEnum.AgencyApplicationFormModal]: async (el) => {
    const { setupAgencyApplicationFormModal } = await import(
      "./LayoutFeatures/AgencyApplicationFormModal/setupAgencyApplicationFormModal"
    );
    setupAgencyApplicationFormModal();
  },
  [VueFunctionalityEnum.MemberApplicationFormModal]: async (el) => {
    const { setupMemberApplicationFormModal } = await import(
      "./LayoutFeatures/MemberApplicationFormModal/setupMemberApplicationFormModal"
    );
    setupMemberApplicationFormModal();
  },
  [VueFunctionalityEnum.LayoutNotifications]: async (el) => {
    // see https://vuejs.org/v2/guide/components-dynamic-async.html#Async-Components
    const LayoutNotifications = (await import("./LayoutFeatures/LayoutNotifications/LayoutNotifications.vue")).default;
    new Vue({
      store,
      render: (h) => h(LayoutNotifications),
    }).$mount(el);
  },
};

void (async () => {
  const $vueFunctionalities = $(".vue-functionality");
  if (!$vueFunctionalities.length) {
    console.warn("gotta have .vue-functionality in this page");
  }
  async function loadFunctionalityForEl(el: HTMLElement) {
    const LOADED_DATA_MARK = "functionality-loaded";
    const loaded = $(el).data(LOADED_DATA_MARK);
    if (loaded) return;
    $(el).data(LOADED_DATA_MARK, true);
    const functionality = $(el).data("functionality-id") as VueFunctionalityEnum;
    const loader = vueFunctionalityLoaders[functionality];
    assert(loader, `loader for ${functionality} is not found!`);
    await loader(el);
  }
  for (const el of $vueFunctionalities.get()) {
    await loadFunctionalityForEl(el);
  }

  //in case there are more functionality elements after the mount point
  $(async () => {
    const $vueFunctionalitiesFull = $(".vue-functionality");
    for (const el of $vueFunctionalitiesFull.get()) {
      await loadFunctionalityForEl(el);
    }
  });
})();
