
import { FhtConstantsEnum } from "./FhtConstantsEnum"
export function describeFhtConstantsEnum(e: FhtConstantsEnum): string{
    switch(e){
        case FhtConstantsEnum.CzCustmerServiceHotline:
            return "0519-68688008";
        case FhtConstantsEnum.CzCustmerServiceHotlineExtra:
            return "17712790051";
        case FhtConstantsEnum.NjCustmerServiceHotline:
            return "025-58505218";
        case FhtConstantsEnum.NjAddress:
            return "江苏省南京市秦淮区集庆路198号泽天写字楼4F";
        case FhtConstantsEnum.CzAddress:
            return "江苏省常州市西横街10号创意产业园3号楼";
        case FhtConstantsEnum.NjCompanyName:
            return "江苏火火网络科技有限公司";
        case FhtConstantsEnum.CzCompanyName:
            return "江苏火火网络营销服务有限公司";
        case FhtConstantsEnum.NjPostCode:
            return "210000";
        case FhtConstantsEnum.CzPostCode:
            return "213003";
        case FhtConstantsEnum.CzCustmerServiceHotlineSecondExtra:
            return "15261412393";
        default:
            throw new Error(`${e} is an invalid FhtConstantsEnum`);
    }
}
		