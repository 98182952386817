import Vue from "vue";
import Router, { RouteConfig } from "vue-router";
import { userCenterMenuRoutes, pathForUserCenterPage } from "./userCenterMenus";
import { enterpriseCenterMenuRoutes } from "./enterpriseCenterMenus";
import { CenterTab } from "./";
import { UserCenterMenuEnum } from "@/apis/model/UserCenterMenuEnum";

Vue.config.devtools = !!process.env.DEVTOOL;

Vue.use(Router);

export const centerRouteConfigs: RouteConfig[] = [
  {
    // eslint-disable-next-line fht-eslint-rules/string-literal-validator
    path: `/(${CenterTab.UserCenter}|${CenterTab.CompanyCenter})`,
    name: "center",
    component: () => import(/* webpackChunkName: "center" */ "./CenterLayout.vue"),
    children: userCenterMenuRoutes.concat(enterpriseCenterMenuRoutes).concat([
      {
        path: "*",
        redirect: pathForUserCenterPage(UserCenterMenuEnum.UserHome),
      },
    ]),
  },
];
