import { CursorPaginationResultBroadcastNotificationViewModel } from "../model/CursorPaginationResultBroadcastNotificationViewModel";
import { UnreadCountWithLastItemViewModelBroadcastNotificationViewModel } from "../model/UnreadCountWithLastItemViewModelBroadcastNotificationViewModel";
import { CursorPaginationResultNotificationModelBase } from "../model/CursorPaginationResultNotificationModelBase";
import { UnreadCountWithLastItemViewModelNotificationModelBase } from "../model/UnreadCountWithLastItemViewModelNotificationModelBase";
import { MarkNotificationsAsReadInputModel } from "../model/MarkNotificationsAsReadInputModel";
// FHT Swagger jQuery client V2
/* eslint-disable */
import { BaseApi } from "./api";
export class NotificationApiApi extends BaseApi {
    public notificationApiGetBroadcasts = (p: {
        "args.limit": number;
        "args.cursor"?: number | null;
    }): Promise<CursorPaginationResultBroadcastNotificationViewModel> => {
        let localVarPath = '/api/notifications/broadcasts';
        this.r(p, ["args.limit",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public notificationApiGetBroadcastsUnreadCount = (p: {
        "args.lastId"?: number | null;
    }): Promise<UnreadCountWithLastItemViewModelBroadcastNotificationViewModel> => {
        let localVarPath = '/api/notifications/broadcasts/unreadcount';
        this.r(p, []);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public notificationApiGetNotifications = (p: {
        "args.limit": number;
        "args.cursor"?: number | null;
    }): Promise<CursorPaginationResultNotificationModelBase> => {
        let localVarPath = '/api/notifications/notifications';
        this.r(p, ["args.limit",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public notificationApiGetNotificationsUnReadCount = (p: {
        "args.lastId"?: number | null;
    }): Promise<UnreadCountWithLastItemViewModelNotificationModelBase> => {
        let localVarPath = '/api/notifications/notifications/unreadcount';
        this.r(p, []);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public notificationApiIgnoreAllNotification = (): Promise<void> => {
        let localVarPath = '/api/notifications/_ignoreall';
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, {}, bodyDict) as any;
    };
    public notificationApiMarkNotificationAsRead = (p: {
        "model": MarkNotificationsAsReadInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/notifications/_markasread';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public notificationApiNotificationMarkAsRead = (p: {
        "notificationId": number;
    }): Promise<void> => {
        let localVarPath = '/api/notifications/{notificationId}'
            .replace('{' + 'notificationId' + '}', String(p["notificationId"]));
        this.r(p, ["notificationId",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
}
