import { describeFhtConstantsEnum } from "./apis/model/describeFhtConstantsEnum";
import { describeFhtWebCompanyHostTypeEnum } from "./apis/model/describeFhtWebCompanyHostTypeEnum";
import { describeFhtWebHostTypeEnum } from "./apis/model/describeFhtWebHostTypeEnum";
import { FhtConstantsEnum } from "./apis/model/FhtConstantsEnum";
import { FhtWebCompanyHostTypeEnum } from "./apis/model/FhtWebCompanyHostTypeEnum";
import { FhtWebHostTypeEnum } from "./apis/model/FhtWebHostTypeEnum";

// eslint-disable-next-line fht-eslint-rules/string-literal-validator
export const VERIFED_ICON_URL = "https://img2.fht360.com/82/50/8250872ea73749ac928f25d3d8ff4f79.png@!100wh-fill";
// eslint-disable-next-line fht-eslint-rules/string-literal-validator
export const AUTOCOMPLETE_PRODUCT_URL = "/api/products/autoCompleteMyProducts";
// eslint-disable-next-line fht-eslint-rules/string-literal-validator
export const REG_URL = "/Account/Register";
// eslint-disable-next-line fht-eslint-rules/string-literal-validator
export const FIND_PASSWORD_URL = "/Account/FindPassword";
// eslint-disable-next-line fht-eslint-rules/string-literal-validator
export const DEFAULT_AVATAR_URL =
  // eslint-disable-next-line fht-eslint-rules/string-literal-validator
  "https://img2.fht360.com/2021/2/25/13/32e500f96a96496ea20d0de3486e2bfb-400x400.png@!100wh-fill";
export const DEFAULT_AVATAR_URL_SMALL =
  // eslint-disable-next-line fht-eslint-rules/string-literal-validator
  "https://img2.fht360.com/2021/2/25/13/32e500f96a96496ea20d0de3486e2bfb-400x400.png@!44wh-fill";
export const DEFAULT_COMPANY_LOGO_URL =
  // eslint-disable-next-line fht-eslint-rules/string-literal-validator
  "https://img2.fht360.com/82/bf/13da66c4479b957cdf4462ffbac0.png@!300wh-fill";

// eslint-disable-next-line fht-eslint-rules/string-literal-validator
export const LOGIN_URL = "/Account/Login";

export const IS_DEV = process.env.NODE_ENV === "development";

export const PAGINATION_SIZES = IS_DEV ? [5, 10] : [10, 20, 50, 100];

export const EXPIRY_INTERVAL_IN_SECONDS = 3600 * 24 * 7;

export const MINIMUM_PRODUCTS_FOR_DISPLAY_SHOP = 4;

export const LOCAL_WEB_HOST = describeFhtWebHostTypeEnum(FhtWebHostTypeEnum.Local);

export const LOCAL_COMPANY_HOST = describeFhtWebCompanyHostTypeEnum(FhtWebCompanyHostTypeEnum.MobileDevelopment);

export const PRODUCTION_WEB_HOST = describeFhtWebHostTypeEnum(FhtWebHostTypeEnum.Production);

export const PRODUCTION_COMPANY_HOST = describeFhtWebCompanyHostTypeEnum(FhtWebCompanyHostTypeEnum.Production);

// eslint-disable-next-line fht-eslint-rules/string-literal-validator
const fd = (id: number) => PRODUCTION_WEB_HOST + "/OfficialSite/Detail/" + id;

//发布建议HREF
export const PUBLISH_RECOMMENDATIONS_HREF = fd(2);
//文章发布规则HREF
export const ARTICLE_PUBLISH_RULES_HREF = fd(3);
//TKD建议范例
export const TKD_SETTING_RULE_HREF = fd(6);
//信息发布规则
export const INFO_PUBLISH_RULE_HREF = fd(5);
export const CUSTOMER_SERVICE_NO = describeFhtConstantsEnum(FhtConstantsEnum.CzCustmerServiceHotline);

//OAuth Login related
export const FHT_PC_ALIPAY_SANDBOX_APPID = "2021000117679218";
export const FHT_PC_ALIPAY_PRODUCTION_APPID = "2021002144677458";

export const FHT_PC_WECHAT_APPID = "wx7441ced1a6e9eaf4";

export const FHT_PC_QQ_APPID = "101958034";

export const DEV_HOST_PORT = "114.221.6.128";
//常州客服电话
export const CZ_CUSTOMER_SERVICE_TEL = "0519-68688008";
//南京客服电话
export const NJ_CUSTOMER_SERVICE_TEL = "025-58505206";

export const TA10_PC_COVER_IMAGE =
  // eslint-disable-next-line fht-eslint-rules/string-literal-validator
  "https://img2.fht360.com/2021/10/29/13/629b562faa5442b0b5583e64e6969c56-1440x3200.jpg@!origin";

export const TA10_MOBILE_COVER_IMAGE =
  // eslint-disable-next-line fht-eslint-rules/string-literal-validator
  "https://img2.fht360.com/2021/10/29/15/85ada61de2494193aaf93c36edf5bf66-750x4872.jpg@!origin";

// export const VIDEO_UPLOAD_URL = IS_DEV
//   ? `${LOCAL_WEB_HOST}/Upload/VideoUpload`
//   : `${PRODUCTION_WEB_HOST}/Upload/VideoUpload`;

export const VIDEO_UPLOAD_URL = "/Upload/VideoUpload";

export enum MediaTypeEnum {
  ImageOnly = "ImageOnly",
  VideoOnly = "VideoOnly",
  ImageOrVideo = "ImageOrVideo",
}
