import { StringViewModel } from "../model/StringViewModel";
import { AutoCompleteInputModel } from "../model/AutoCompleteInputModel";
import { CustomCategoryViewModel } from "../model/CustomCategoryViewModel";
import { AutoCompleteProductViewModel } from "../model/AutoCompleteProductViewModel";
import { SystemCategoryViewModel } from "../model/SystemCategoryViewModel";
import { CategoryAutoCompleteViewModel } from "../model/CategoryAutoCompleteViewModel";
import { ActionableBatchInputModel } from "../model/ActionableBatchInputModel";
import { BatchModifyCustomCategoryInputModel } from "../model/BatchModifyCustomCategoryInputModel";
import { BatchModifySystemCategoryInputModel } from "../model/BatchModifySystemCategoryInputModel";
import { BoolViewModel } from "../model/BoolViewModel";
import { ProductInputModel } from "../model/ProductInputModel";
import { CreatedViewModel } from "../model/CreatedViewModel";
import { NumberViewModel } from "../model/NumberViewModel";
import { QueryResultProductDetailViewModel } from "../model/QueryResultProductDetailViewModel";
import { CategoryItemViewModel } from "../model/CategoryItemViewModel";
import { ProductRecommendViewModel } from "../model/ProductRecommendViewModel";
import { ProductBondViewModel } from "../model/ProductBondViewModel";
import { Fht1ProductSyncViewModel } from "../model/Fht1ProductSyncViewModel";
import { ProductHotWordPromotionViewModel } from "../model/ProductHotWordPromotionViewModel";
import { ProductAutoCompleteViewModel } from "../model/ProductAutoCompleteViewModel";
import { WapProductDetailViewModel } from "../model/WapProductDetailViewModel";
import { QueryResultProductListItemViewModel } from "../model/QueryResultProductListItemViewModel";
import { UpdateProductOrderInputModel } from "../model/UpdateProductOrderInputModel";
// FHT Swagger jQuery client V2
/* eslint-disable */
import { BaseApi } from "./api";
import { SearchProductTypeEnum } from "../model/SearchProductTypeEnum";
import { OrderByTypeEnum } from "../model/OrderByTypeEnum";
import { OrderTypeEnum } from "../model/OrderTypeEnum";
import { AuditDisplayStatusEnum } from "../model/AuditDisplayStatusEnum";
import { TimeRuleEnum } from "../model/TimeRuleEnum";
export class ProductApiApi extends BaseApi {
    public productApiAutoCompleteCmsProducts = (p: {
        "model.companyId": number;
        "model.q"?: string | null;
    }): Promise<Array<StringViewModel>> => {
        let localVarPath = '/api/products/autoCompleteCmsProducts';
        this.r(p, ["model.companyId",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiAutoCompleteCustomCategories = (p: {
        "args": AutoCompleteInputModel;
    }): Promise<Array<CustomCategoryViewModel>> => {
        let localVarPath = '/api/products/customCategories/_autocomplete';
        this.r(p, ["args",]);
        let bodyDict = p["args"];
        const _p = p as any;
        delete _p["args"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public productApiAutoCompleteMyProducts = (p: {
        "model.page": number;
        "model.q"?: string | null;
        "model.ids"?: Array<number> | null;
    }): Promise<Array<AutoCompleteProductViewModel>> => {
        let localVarPath = '/api/products/autoCompleteMyProducts';
        this.r(p, ["model.page",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiAutoCompleteSystemCategories = (p: {
        "args": AutoCompleteInputModel;
    }): Promise<Array<SystemCategoryViewModel>> => {
        let localVarPath = '/api/products/systemCategories/_autocomplete';
        this.r(p, ["args",]);
        let bodyDict = p["args"];
        const _p = p as any;
        delete _p["args"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public productApiAutoCompleteUsedSystemCategories = (p: {
        "q": string;
    }): Promise<Array<CategoryAutoCompleteViewModel>> => {
        let localVarPath = '/api/products/systemCategories/_autocompleteused';
        this.r(p, ["q",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiBatchCancelTop = (p: {
        "model": ActionableBatchInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/products/_batchCancelTop';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public productApiBatchDelete = (p: {
        "model": ActionableBatchInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/products/_batchdelete';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public productApiBatchModifyCustomCategory = (p: {
        "model": BatchModifyCustomCategoryInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/products/_batchModifyCustomCategory';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public productApiBatchModifySystemCategory = (p: {
        "model": BatchModifySystemCategoryInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/products/_batchModifySystemCategory';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public productApiBatchSetTop = (p: {
        "model": ActionableBatchInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/products/_batchSetTop';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public productApiCheckProductTitleUnique = (p: {
        "title": string;
        "companyId"?: number | null;
        "productId"?: number | null;
    }): Promise<BoolViewModel> => {
        let localVarPath = '/api/products/_checkTitleUnique';
        this.r(p, ["title",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiCreateProduct = (p: {
        "model": ProductInputModel;
    }): Promise<CreatedViewModel> => {
        let localVarPath = '/api/products/_create';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public productApiCustomCategoryHasProductsCount = (p: {
        "customCategoryId": number;
    }): Promise<NumberViewModel> => {
        let localVarPath = '/api/products/customCategories/{customCategoryId}/productcount'
            .replace('{' + 'customCategoryId' + '}', String(p["customCategoryId"]));
        this.r(p, ["customCategoryId",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiFilterProducts = (p: {
        "q": string;
        "args.offset": number;
        "args.limit": number;
        "customCategoryId"?: number | null;
    }): Promise<QueryResultProductDetailViewModel> => {
        let localVarPath = '/api/products/filterProducts';
        this.r(p, ["q", "args.offset", "args.limit",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiGetAllSystemCategories = (): Promise<Array<SystemCategoryViewModel>> => {
        let localVarPath = '/api/products/allSystemCategories';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public productApiGetCompanyAllCustomCategories = (): Promise<Array<CustomCategoryViewModel>> => {
        let localVarPath = '/api/products/allCustomCategories';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public productApiGetCompanyAllCustomCategoriesCachedAsync = (): Promise<Array<CustomCategoryViewModel>> => {
        let localVarPath = '/api/products/allCustomCategoriesCached';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public productApiGetCustomCategoriesByParentIdAsync = (p: {
        "parentId"?: number | null;
    }): Promise<Array<CategoryItemViewModel>> => {
        let localVarPath = '/api/products/customCategories/children';
        this.r(p, []);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiGetHotProductsAsync = (p: {
        "args.top": number;
    }): Promise<Array<ProductRecommendViewModel>> => {
        let localVarPath = '/api/products/hotProducts';
        this.r(p, ["args.top",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiGetLatestProductsAsync = (p: {
        "args.top": number;
    }): Promise<Array<ProductRecommendViewModel>> => {
        let localVarPath = '/api/products/latestProducts';
        this.r(p, ["args.top",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiGetMyCompanyProductCount = (): Promise<NumberViewModel> => {
        let localVarPath = '/api/products/totalCount';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public productApiGetMyCompanyTodayCreateProductCountAsync = (): Promise<NumberViewModel> => {
        let localVarPath = '/api/products/todayPublishProductCountCount';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public productApiGetProductForInput = (p: {
        "productId": number;
    }): Promise<ProductInputModel> => {
        let localVarPath = '/api/products/_getProductForInput';
        this.r(p, ["productId",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiGetRecommendProductsAsync = (p: {
        "args.top": number;
    }): Promise<Array<ProductRecommendViewModel>> => {
        let localVarPath = '/api/products/recommendProducts';
        this.r(p, ["args.top",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiGetRelatedProducts = (p: {
        "args.top": number;
    }): Promise<Array<ProductRecommendViewModel>> => {
        let localVarPath = '/api/products/relatedProducts';
        this.r(p, ["args.top",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiGetSimilarProducts = (p: {
        "id": number;
        "args.offset": number;
        "args.limit": number;
    }): Promise<Array<ProductBondViewModel>> => {
        let localVarPath = '/api/products/{id}/similar'
            .replace('{' + 'id' + '}', String(p["id"]));
        this.r(p, ["id", "args.offset", "args.limit",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiGetStatProductIdsAsync = (p: {
        "args.limit": number;
        "args.searchProductType": SearchProductTypeEnum;
        "args.companyId": number;
    }): Promise<Array<number>> => {
        let localVarPath = '/api/products/_getstatproductids';
        this.r(p, ["args.limit", "args.searchProductType", "args.companyId",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public productApiGetSyncFht1Products = (p: {
        "fht1ProductIds": Array<number>;
    }): Promise<Array<Fht1ProductSyncViewModel>> => {
        let localVarPath = '/api/products/getSyncFht1Products';
        this.r(p, ["fht1ProductIds",]);
        let bodyDict = undefined;
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public productApiGetSystemCategoriesByParentId = (p: {
        "parentId"?: number | null;
    }): Promise<Array<SystemCategoryViewModel>> => {
        let localVarPath = '/api/products/systemCategories/children';
        this.r(p, []);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiGetUsedCustomCategoriesOfCompanyCachedAsync = (): Promise<Array<CustomCategoryViewModel>> => {
        let localVarPath = '/api/products/allCompanyUsedCustomCategories';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public productApiGetUsedSystemCategoriesOfCompanyCachedAsync = (): Promise<Array<SystemCategoryViewModel>> => {
        let localVarPath = '/api/products/allCompanyUsedSystemCategories';
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, {}, bodyDict) as any;
    };
    public productApiHotWordPromotion = (p: {
        "args.top": number;
    }): Promise<Array<ProductHotWordPromotionViewModel>> => {
        let localVarPath = '/api/products/hotwordpromotions';
        this.r(p, ["args.top",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiMyProducts = (p: {
        "args.orderByType": OrderByTypeEnum;
        "args.orderType": OrderTypeEnum;
        "args.currPage": number;
        "args.limit": number;
        "args.fromTime"?: Date | null;
        "args.endTime"?: Date | null;
        "args.showStatus"?: AuditDisplayStatusEnum | null;
        "args.q"?: string | null;
        "args.systemCategoryId"?: number | null;
        "args.customCategoryId"?: number | null;
    }): Promise<QueryResultProductDetailViewModel> => {
        let localVarPath = '/api/products/myProducts';
        this.r(p, ["args.orderByType", "args.orderType", "args.currPage", "args.limit",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiProductAutoComplete = (p: {
        "keyword": string;
    }): Promise<Array<ProductAutoCompleteViewModel>> => {
        let localVarPath = '/api/products/_autoComplete';
        this.r(p, ["keyword",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiProductDetail = (p: {
        "productId": number;
    }): Promise<WapProductDetailViewModel> => {
        let localVarPath = '/api/products/{productId}'
            .replace('{' + 'productId' + '}', String(p["productId"]));
        this.r(p, ["productId",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiSearch = (p: {
        "args.isActiveIndexSort": boolean;
        "args.isOnlyCompanyVerified": boolean;
        "args.isOnlyVipCompany": boolean;
        "args.isMobileUser": boolean;
        "args.limit": number;
        "args.currPage": number;
        "args.regionId"?: number | null;
        "args.productCategoryId"?: number | null;
        "args.customCategoryId"?: number | null;
        "args.timeRule"?: TimeRuleEnum | null;
        "args.q"?: string | null;
        "args.latitude"?: number | null;
        "args.longitude"?: number | null;
        "args.topicTypeId"?: number | null;
        "args.pinYin"?: string | null;
        "args.topicTypeName"?: string | null;
        "args.industryId"?: number | null;
        "args.regionName"?: string | null;
        "args.timeRuleName"?: string | null;
    }): Promise<QueryResultProductListItemViewModel> => {
        let localVarPath = '/api/ProductApi';
        this.r(p, ["args.isActiveIndexSort", "args.isOnlyCompanyVerified", "args.isOnlyVipCompany", "args.isMobileUser", "args.limit", "args.currPage",]);
        let bodyDict = undefined;
        return this.ajax("GET", localVarPath, p, bodyDict) as any;
    };
    public productApiUpdateOrder = (p: {
        "model": UpdateProductOrderInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/products/_updateOrder';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
    public productApiUpdateProduct = (p: {
        "model": ProductInputModel;
    }): Promise<void> => {
        let localVarPath = '/api/products/_update';
        this.r(p, ["model",]);
        let bodyDict = p["model"];
        const _p = p as any;
        delete _p["model"];
        return this.ajax("POST", localVarPath, p, bodyDict) as any;
    };
}
