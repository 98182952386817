export const SAVE_TOKEN_MUTATION = "mutation:token";
export const SAVE_MY_BASIC_INFO_MUTATION = "mutation:my_info";
export const SAVE_MY_PROFILE_MUTATION = "mutation:my_profile";
export const SAVE_MY_COMPANY_MUTATION = "mutation:my_company";
export const DELETE_TOKEN_MUTATION = "mutation:delete_token";
export const SAVE_NOTIFICATION_COUNT = "mutation:notification_count";
export const SAVE_PRODUCT_COUNT = "mutation:product_count";
export const SAVE_PRODUCT_BADGE_ALERT_STATE = "mutation:product_badge_alert_state_";
export const SAVE_INVITING_COMPANY_COUNT = "mutation:inviting_companies";
export const SAVE_APPLY_REQUEST_COUNT = "mutation:applied_companies";
export const SAVE_INCOMMING_COMPANY_MEMBER_APPLICATION_COUNT = "mutation:incomming_company_member_applications";
