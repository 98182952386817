export const LOGIN_ACTION = "action:login";
export const LOGOUT_ACTION = "action:logout";
export const LOAD_MY_USER_INFO = "action:load_my_user_info";
export const LOAD_MY_COMPANY_MEMBER_INFO = "action:load_my_company_member_info";
export const RELOAD_MY_COMPANY_MEMBER_INFO = "action:reload_my_company_member_info";

export const LOAD_MY_PROFILE = "action:load_my_profile";
export const LOAD_MY_COMPANY_PROFILE = "action:load_my_company_profile";
export const LOAD_NOTIFICATION_COUNT = "action:load_notification_count";
export const LOAD_PRODUCT_COUNT = "action:load_product_count";
export const LOAD_PRODUCT_BADGE_ALERT_STATE = "action:load_product_badge_alert";
export const LOAD_INVITING_COMPANY_COUNT = "action:load_inviting_company_count";
export const LOAD_APPLY_REQUEST_COUNT = "action:load_apply_request_count";
export const LOAD_INCOMMING_COMPANY_MEMBER_APPLICATION_COUNT = "action:load_incomming_company_member_application_count";

export const LEFT_COMPANY = "action:left_company";

export const MARK_LAST_NOTIFICATION_ID = "action:mark_last_notification_id";
export const MARK_LAST_BROADCAST_ID = "action:mark_last_broadcast_id";
export const MARK_LAST_APPLY_REQUEST_ID = "action:mark_last_apply-request_id";
export const MARK_LAST_INVITING_COMPANY_ID = "action:mark_last_inviting_company_id";
export const MARK_LAST_INCOMMING_COMPANY_MEMBER_APPLICATION_ID =
  "action:mark_last_incomming_company_member_appllication_id";
