import nameof from "ts-nameof.macro";
import { RouteConfig } from "vue-router";
import { IAuthMetaModel } from "@/routerUtils";
import { AdditionalPage } from "@/additionalPages";

const loginRequiredMeta: IAuthMetaModel = {
  loginRequired: true,
};

export const editTemplateRouteConfigs: RouteConfig[] = [
  {
    path: "/" + nameof(AdditionalPage.EditTemplate),
    name: AdditionalPage.EditTemplate,
    meta: loginRequiredMeta,
    component: () => import(/* webpackChunkName: "EditTemplatePageImpl" */ "@/pages/EditTemplate/EditTemplate.vue"),
  },
];
