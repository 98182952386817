import { Component, RouteConfig } from "vue-router/types/router";
import RouterViewWrapper from "@/components/RouterViewWrapper.vue";
import assert from "assert";
import { ICenterRouteMetaModel, ICenterNavigationMetaModel } from "./RouteMetaModel";
import { enterpriseCenterEligibilityMap } from "./eligibilityMap";
import { mapRouteToProps } from "@/routerUtils";
import { CenterTab } from ".";
import { EnterpriseCenterMenuEnum } from "@/apis/model/EnterpriseCenterMenuEnum";

export const enterpriseCenterMenuComponentMap: Partial<{
  [k in EnterpriseCenterMenuEnum]: Component;
}> = {
  [EnterpriseCenterMenuEnum.EnterpriseHome]: () =>
    import(/* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/Home/EnterpriseCenterHome.vue"),
  [EnterpriseCenterMenuEnum.CreateProduct]: () =>
    import(
      /* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/ProductManagement/ProductList/CreateProduct.vue"
    ),
  [EnterpriseCenterMenuEnum.CreateProductFinished]: () =>
    import(
      /* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/ProductManagement/ProductList/CreateProductFinished.vue"
    ),
  [EnterpriseCenterMenuEnum.EditCustomCategories]: () =>
    import(
      /* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/ProductManagement/EditCustomCategories/EditCustomCategories.vue"
    ),
  [EnterpriseCenterMenuEnum.ArticleList]: () =>
    import(
      /* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/ArticleManagement/ArticleList/ArticleList.vue"
    ),
  [EnterpriseCenterMenuEnum.CreateArticle]: () =>
    import(
      /* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/ArticleManagement/ArticleList/CreateArticle.vue"
    ),
  [EnterpriseCenterMenuEnum.CreateArticleFinished]: () =>
    import(
      /* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/ArticleManagement/ArticleList/CreateArticleFinished.vue"
    ),
  [EnterpriseCenterMenuEnum.EditArticleTypes]: () =>
    import(
      /* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/ArticleManagement/EditArticleTypes/EditArticleTypes.vue"
    ),
  [EnterpriseCenterMenuEnum.SelectTemplate]: () =>
    import(
      /* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/SiteRenovation/SelectTemplate/SelectTemplate.vue"
    ),
  [EnterpriseCenterMenuEnum.EditNavigations]: () =>
    import(
      /* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/SiteRenovation/EditNavigations/EditNavigations.vue"
    ),
  [EnterpriseCenterMenuEnum.SEOSetting]: () =>
    import(/* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/SiteRenovation/SeoSetting/SEOSetting.vue"),
  [EnterpriseCenterMenuEnum.FriendlyLink]: () =>
    import(
      /* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/SiteRenovation/FriendlyLink/FriendlyLink.vue"
    ),
  [EnterpriseCenterMenuEnum.SiteExamination]: () =>
    import(
      /* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/SiteRenovation/SiteExamination/SiteExamination.vue"
    ),
  [EnterpriseCenterMenuEnum.CompanyProfile]: () =>
    import(
      /* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/CompanyManagement/CompanyProfile/CompanyProfile.vue"
    ),
  [EnterpriseCenterMenuEnum.MemberManagement]: () =>
    import(
      /* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/CompanyManagement/MemberManagement/MemberManagement.vue"
    ),
  [EnterpriseCenterMenuEnum.MemberInvitations]: () =>
    import(
      /* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/CompanyManagement/MemberManagement/MemberInvitations.vue"
    ),
  [EnterpriseCenterMenuEnum.MemberApplications]: () =>
    import(
      /* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/CompanyManagement/MemberManagement/MemberApplications.vue"
    ),
  [EnterpriseCenterMenuEnum.ProductList]: () =>
    import(
      /* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/ProductManagement/ProductList/ProductList.vue"
    ),
  [EnterpriseCenterMenuEnum.CompanyAuthentication]: () =>
    import(
      /* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/CompanyManagement/CompanyAuthentication/CompanyAuthentication.vue"
    ),
  [EnterpriseCenterMenuEnum.Statistics]: () =>
    import(/* webpackChunkName: "enterpriseCenter" */ "./EnterpriseCenter/Statistics/ProductStatistics.vue"),
};

export type EnterpriseCenterMenuType = [EnterpriseCenterMenuEnum, EnterpriseCenterMenuEnum[] | undefined];

export const enterpriseCenterMenus: EnterpriseCenterMenuType[] = [
  [EnterpriseCenterMenuEnum.EnterpriseHome, undefined],
  [
    EnterpriseCenterMenuEnum.ProductManagement,
    [
      EnterpriseCenterMenuEnum.CreateProduct,
      EnterpriseCenterMenuEnum.ProductList,
      EnterpriseCenterMenuEnum.EditCustomCategories,
    ],
  ],
  [
    EnterpriseCenterMenuEnum.ArticleManagement,
    [
      EnterpriseCenterMenuEnum.CreateArticle,
      EnterpriseCenterMenuEnum.ArticleList,
      EnterpriseCenterMenuEnum.EditArticleTypes,
    ],
  ],
  [
    EnterpriseCenterMenuEnum.SiteRenovation,
    [
      EnterpriseCenterMenuEnum.SelectTemplate,
      EnterpriseCenterMenuEnum.EditNavigations,
      EnterpriseCenterMenuEnum.SEOSetting,
      EnterpriseCenterMenuEnum.FriendlyLink,
      EnterpriseCenterMenuEnum.SiteExamination,
    ],
  ],
  [
    EnterpriseCenterMenuEnum.CompanyManagement,
    [EnterpriseCenterMenuEnum.MemberManagement, EnterpriseCenterMenuEnum.CompanyAuthentication],
  ],
  [EnterpriseCenterMenuEnum.Statistics, undefined],
];

enterpriseCenterMenus.forEach(([topMenu]) => {
  if (!enterpriseCenterMenuComponentMap[topMenu]) {
    enterpriseCenterMenuComponentMap[topMenu] = RouterViewWrapper;
  }
});

export function pathForEnterpriseCenterPage(menu: EnterpriseCenterMenuEnum): string {
  // eslint-disable-next-line fht-eslint-rules/string-literal-validator
  return `/${CenterTab.CompanyCenter}/` + menu;
}

const _enterpriseCenterMenuRoutes: RouteConfig[] = enterpriseCenterMenus.map((m) => {
  const menu = m[0];
  const subMenus = m[1];
  const parentMeta: ICenterRouteMetaModel = {
    loginRequired: true,
    menuPath: [menu],
    eligiblity: enterpriseCenterEligibilityMap[menu],
  };
  const parentRoute = {
    path: pathForEnterpriseCenterPage(menu),
    name: menu,
    meta: parentMeta,
    component: enterpriseCenterMenuComponentMap[menu],
    redirect: !subMenus ? undefined : pathForEnterpriseCenterPage(subMenus[0]), //default parent menu to the first child menu
    children: (subMenus || []).map((subMenu) => {
      const childMeta: ICenterRouteMetaModel = {
        loginRequired: true,
        menuPath: [menu, subMenu],
        eligiblity: enterpriseCenterEligibilityMap[subMenu],
      };
      return {
        path: pathForEnterpriseCenterPage(subMenu),
        name: subMenu,
        meta: childMeta,
        component: enterpriseCenterMenuComponentMap[subMenu],
        // courtesy of https://stackoverflow.com/questions/44783787/bind-query-to-props-with-vue-router/50782176#50782176
        props: mapRouteToProps,
      } as RouteConfig;
    }),
  } as RouteConfig;
  return parentRoute;
});

function makeMenuToRouteConfigMap(routes: RouteConfig[]) {
  const _map: Partial<{ [k in EnterpriseCenterMenuEnum]: RouteConfig }> = {};
  function _makeMenuToRouteConfigMapImpl(routes: RouteConfig[]) {
    for (const r of routes) {
      assert(r.name, "r.name falsy");
      const m = EnterpriseCenterMenuEnum[r.name as EnterpriseCenterMenuEnum];
      if (!m) continue;
      _map[m] = r;
      if (r.children) {
        _makeMenuToRouteConfigMapImpl(r.children);
      }
    }
  }
  _makeMenuToRouteConfigMapImpl(routes);
  return _map;
}

const menuToRouteConfigMap = makeMenuToRouteConfigMap(_enterpriseCenterMenuRoutes);

export function appendAdditionalNavigationPages(routes: RouteConfig[]): RouteConfig[] {
  function appendNavigationPageToMenu(parent: EnterpriseCenterMenuEnum, page: EnterpriseCenterMenuEnum) {
    const menuRouteConfig = menuToRouteConfigMap[parent];
    assert(menuRouteConfig, "menuRouteConfig falsy");
    const parentMeta = menuRouteConfig.meta as ICenterNavigationMetaModel;
    const parentNavigationPaths = parentMeta.navigationPath || [];
    const meta: ICenterNavigationMetaModel = {
      loginRequired: true,
      menuPath: parentMeta.menuPath,
      eligiblity: enterpriseCenterEligibilityMap[page],
      navigationPath: [...parentNavigationPaths, page],
    };
    routes.push({
      path: pathForEnterpriseCenterPage(page),
      name: page,
      component: enterpriseCenterMenuComponentMap[page],
      meta,
    });
  }

  appendNavigationPageToMenu(EnterpriseCenterMenuEnum.EnterpriseHome, EnterpriseCenterMenuEnum.CompanyProfile);
  appendNavigationPageToMenu(EnterpriseCenterMenuEnum.MemberManagement, EnterpriseCenterMenuEnum.MemberInvitations);
  appendNavigationPageToMenu(EnterpriseCenterMenuEnum.MemberManagement, EnterpriseCenterMenuEnum.MemberApplications);
  appendNavigationPageToMenu(EnterpriseCenterMenuEnum.CreateArticle, EnterpriseCenterMenuEnum.CreateArticleFinished);
  appendNavigationPageToMenu(EnterpriseCenterMenuEnum.CreateProduct, EnterpriseCenterMenuEnum.CreateProductFinished);

  return routes;
}

export const enterpriseCenterMenuRoutes = appendAdditionalNavigationPages(_enterpriseCenterMenuRoutes).concat([
  {
    name: "EnterpriseCenter404",
    // eslint-disable-next-line fht-eslint-rules/string-literal-validator
    path: `/${CenterTab.CompanyCenter}/*`,
    component: () => import("./ErrorPages/NotFound.vue"),
  },
]);
