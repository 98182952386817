// FHT Swagger jQuery client V2
/**
 * FHT.Core.CommonTypes.EligibilityType, FHT.Core.CommonTypes, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null
 */
/**
* FHT.Core.CommonTypes.EligibilityType, FHT.Core.CommonTypes, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null
*/
// mark 1 model enum
export enum EligibilityTypeEnum {
    TopicPost = "TopicPost",
    Product = "Product",
    OtherCompanyCommonManagement = "OtherCompanyCommonManagement",
    CompanyVipManagement = "CompanyVipManagement",
    Article = "Article",
    JoinCompany = "JoinCompany",
    InviteCompany = "InviteCompany",
    Chat = "Chat",
    Meetup = "Meetup",
    JoinMeetup = "JoinMeetup",
    AddFriend = "AddFriend"
}
