import { fhtGlobalEnv, IS_PROD } from "@/env";
import { persistentStorage } from "@/utils";

export interface ExpirableActionPayload<T> {
  value: T;
  ts: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getExpirableActionManager<T>(actionName: string, expirySeconds = 7 * 24 * 3600) {
  const key = `action_${actionName}_ts`;
  let expiryInterval = expirySeconds * 1000;
  if (fhtGlobalEnv.Env.DEBUG) {
    expiryInterval = 45 * 1000;
  } else if (!IS_PROD) {
    expiryInterval = 1000000 * 1000;
  }
  return {
    async saveActionValue(value: T): Promise<void> {
      const payload: ExpirableActionPayload<T> = {
        value,
        ts: new Date().getTime(),
      };
      await persistentStorage.setItem(key, JSON.stringify(payload));
    },
    async getEffectiveValue(): Promise<ExpirableActionPayload<T> | undefined> {
      const savedTimestamp = await persistentStorage.getItem(key);
      if (savedTimestamp) {
        let payload: ExpirableActionPayload<T> | undefined;
        try {
          payload = JSON.parse(savedTimestamp) as ExpirableActionPayload<T>;
        } catch (err) {
          //ignore
        }
        if (payload && payload.ts) {
          const d = new Date(payload.ts);
          const isEffective = new Date().getTime() - d.getTime() < expiryInterval;
          if (isEffective) {
            return payload;
          }
        }
      }
      return undefined;
    },
  };
}
