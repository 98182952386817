
import { FhtWebHostTypeEnum } from "./FhtWebHostTypeEnum"
export function describeFhtWebHostTypeEnum(e: FhtWebHostTypeEnum): string{
    switch(e){
        case FhtWebHostTypeEnum.Local:
            return "http://localhost:8001";
        case FhtWebHostTypeEnum.Development:
            return "http://www.developmentfht360web.com";
        case FhtWebHostTypeEnum.MobileDevelopment:
            return "http://www.mobiledevelopmentfht360web.com";
        case FhtWebHostTypeEnum.Staging:
            return "http://www.stagingfht360web.com";
        case FhtWebHostTypeEnum.Production:
            return "https://www.fht360.com";
        default:
            throw new Error(`${e} is an invalid FhtWebHostTypeEnum`);
    }
}
		