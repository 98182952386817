import { Component, RouteConfig } from "vue-router/types/router";
import { ICenterRouteMetaModel, ICenterNavigationMetaModel } from "./RouteMetaModel";
import assert from "assert";
import { userCenterEligibilityMap } from "./eligibilityMap";
import { mapRouteToProps } from "@/routerUtils";
import { UserCenterMenuEnum } from "@/apis/model/UserCenterMenuEnum";

export const userCenterMenuComponentMap: { [k in UserCenterMenuEnum]: Component } = {
  [UserCenterMenuEnum.UserHome]: () => import(/* webpackChunkName: "userCenter" */ "./UserCenter/UserHome.vue"),
  [UserCenterMenuEnum.MyTopicPosts]: () =>
    import(/* webpackChunkName: "userCenter" */ "./UserCenter/TopicPostManagement/MyTopicPosts.vue"),
  [UserCenterMenuEnum.MyCollections]: () =>
    import(/* webpackChunkName: "userCenter" */ "./UserCenter/CollectionManagement/MyCollections.vue"),
  [UserCenterMenuEnum.ViewingHistory]: () =>
    import(/* webpackChunkName: "userCenter" */ "./UserCenter/HistoryManagement/ViewingHistory.vue"),
  [UserCenterMenuEnum.UserProfile]: () =>
    import(/* webpackChunkName: "userCenter" */ "./UserCenter/ProfileManagement/UserProfile.vue"),
  [UserCenterMenuEnum.AccountSecurity]: () =>
    import(/* webpackChunkName: "userCenter" */ "./UserCenter/SecurityManagement/AccountSecurity.vue"),
  [UserCenterMenuEnum.SecuritySettings]: () =>
    import(/* webpackChunkName: "userCenter" */ "./UserCenter/SecurityManagement/SecuritySettings.vue"),
  [UserCenterMenuEnum.AccountBinding]: () =>
    import(/* webpackChunkName: "userCenter" */ "./UserCenter/SecurityManagement/AccountBinding.vue"),
  [UserCenterMenuEnum.MyCompany]: () =>
    import(/* webpackChunkName: "userCenter" */ "./UserCenter/MyCompanyManagement/MyCompany.vue"),
  [UserCenterMenuEnum.JoinCompany]: () =>
    import(/* webpackChunkName: "userCenter" */ "./UserCenter/MyCompanyManagement/JoinCompany.vue"),
  [UserCenterMenuEnum.CreateCompany]: () =>
    import(/* webpackChunkName: "userCenter" */ "./UserCenter/MyCompanyManagement/CreateCompany.vue"),
  [UserCenterMenuEnum.InviteApplies]: () =>
    import(/* webpackChunkName: "userCenter" */ "./UserCenter/InviteApplies/InviteApplies.vue"),
  [UserCenterMenuEnum.MyNotifications]: () =>
    import(/* webpackChunkName: "userCenter" */ "./UserCenter/NotificationManagement/MyNotifications.vue"),
  [UserCenterMenuEnum.CreateTopicPost]: () =>
    import(/* webpackChunkName: "userCenter" */ "./UserCenter/TopicPostManagement/CreateTopicPost.vue"),
  [UserCenterMenuEnum.CreateTopicPostFinished]: () =>
    import(/* webpackChunkName: "userCenter" */ "./UserCenter/TopicPostManagement/CreateTopicPostFinished.vue"),
};

export const userCenterMenus: UserCenterMenuEnum[] = [
  UserCenterMenuEnum.UserHome,
  UserCenterMenuEnum.MyTopicPosts,
  UserCenterMenuEnum.MyCollections,
  UserCenterMenuEnum.ViewingHistory,
  UserCenterMenuEnum.AccountSecurity,
  UserCenterMenuEnum.MyCompany,
  UserCenterMenuEnum.MyNotifications,
];

/**
 * 在/UserCenter下面的相对路径
 * @param menu
 */
export function pathForUserCenterPage(menu: UserCenterMenuEnum): string {
  // eslint-disable-next-line fht-eslint-rules/string-literal-validator
  return "/UserCenter/" + menu;
}

const _userCenterMenuRoutes: RouteConfig[] = userCenterMenus.map((m) => {
  const meta: ICenterRouteMetaModel = {
    loginRequired: true,
    menuPath: [m],
    eligiblity: userCenterEligibilityMap[m],
  };
  return {
    path: pathForUserCenterPage(m),
    name: m,
    meta,
    component: userCenterMenuComponentMap[m],
    // courtesy of https://stackoverflow.com/questions/44783787/bind-query-to-props-with-vue-router/50782176#50782176
    props: mapRouteToProps,
  } as RouteConfig;
});

function makeMenuToRouteConfigMap(routes: RouteConfig[]) {
  const _map: Partial<{ [k in UserCenterMenuEnum]: RouteConfig }> = {};
  function _makeMenuToRouteConfigMapImpl(routes: RouteConfig[]) {
    for (const r of routes) {
      assert(r.name, "r.name falsy");
      const m = UserCenterMenuEnum[r.name as UserCenterMenuEnum];
      if (!m) continue;
      _map[m] = r;
      if (r.children) {
        _makeMenuToRouteConfigMapImpl(r.children);
      }
    }
  }
  _makeMenuToRouteConfigMapImpl(routes);
  return _map;
}

const menuToRouteConfigMap = makeMenuToRouteConfigMap(_userCenterMenuRoutes);

function appendAdditionalNavigationPages(routes: RouteConfig[]) {
  function appendNavigationPageToMenu(parent: UserCenterMenuEnum, page: UserCenterMenuEnum) {
    const menuRouteConfig = menuToRouteConfigMap[parent];
    assert(menuRouteConfig, "menuRouteConfig falsy");
    const parentMeta = menuRouteConfig.meta as ICenterNavigationMetaModel;
    const parentNavigationPaths = parentMeta.navigationPath || [];
    const meta: ICenterNavigationMetaModel = {
      loginRequired: true,
      menuPath: parentMeta.menuPath,
      eligiblity: userCenterEligibilityMap[page],
      navigationPath: [...parentNavigationPaths, page],
    };
    routes.push({
      path: pathForUserCenterPage(page),
      name: page,
      component: userCenterMenuComponentMap[page],
      meta,
    });
  }

  appendNavigationPageToMenu(UserCenterMenuEnum.UserHome, UserCenterMenuEnum.UserProfile);
  appendNavigationPageToMenu(UserCenterMenuEnum.MyCompany, UserCenterMenuEnum.InviteApplies);
  appendNavigationPageToMenu(UserCenterMenuEnum.MyCompany, UserCenterMenuEnum.CreateCompany);
  appendNavigationPageToMenu(UserCenterMenuEnum.MyCompany, UserCenterMenuEnum.JoinCompany);
  appendNavigationPageToMenu(UserCenterMenuEnum.MyTopicPosts, UserCenterMenuEnum.CreateTopicPost);
  appendNavigationPageToMenu(UserCenterMenuEnum.MyTopicPosts, UserCenterMenuEnum.CreateTopicPostFinished);
  return routes;
}

export const userCenterMenuRoutes = appendAdditionalNavigationPages(_userCenterMenuRoutes).concat([
  {
    name: "UserCenter404",
    // eslint-disable-next-line fht-eslint-rules/string-literal-validator
    path: "/UserCenter/*",
    component: () => import("./ErrorPages/NotFound.vue"),
  },
]);
