// ===============================================
// This file is autogenerated - Please do not edit
// ===============================================

/* eslint-disable */

// appName: FHT.Core.Web
// appVersion: v1
// supportsRuleValidator: true
// supportsClassValidator: 
// supportsCustomValidator: true
// supportsDescribeEnum: true
// supportsConvertToEnum: true
// supportsConvertFromEnum: true

// This file generates to api.ts

// ts client for jquery


interface CustomValidatorRule<TFunc extends (...args: any[]) => Promise<void> | void = any> {
  validator: TFunc;
  trigger: "blur";
}

export interface CustomValidatorMap {
  sensitiveWord(msg: string): CustomValidatorRule<(rule: unknown, value: string) => Promise<void>>;
  htmlTextLength(
    msg: string,
    min: number,
    max: number,
  ): CustomValidatorRule<(rule: unknown, value: string) => Promise<void>>;
  elementCount(
    msg: string,
    min: number,
    max: number,
  ): CustomValidatorRule<(rule: unknown, value: Array<unknown>) => Promise<void>>;
  birthYear(msg: string, maxAge: number): CustomValidatorRule<(rule: unknown, value: number) => Promise<void>>;
  enforceTrue(msg: string): CustomValidatorRule<(rule: unknown, value: boolean) => Promise<void>>;
}

// export function htmlTextLength(msg: string, min: number, max: number) {
//     return async (rule: unknown, html: string) => {
//       const d = document.createElement("div");
//       d.innerHTML = html;
//       const text = d.textContent || "";
//       if (text.length < min || text.length > max) {
//         throw new Error(msg);
//       }
//     };
//   }


export interface ValidationRuleBase {
  message?: string;
}

export interface RequiredRule extends ValidationRuleBase {
  type: "string" | "number" | "boolean" | "object" | "array";
  required?: boolean;
}

export interface RangeRule extends ValidationRuleBase {
  type: "string" | "number";
  min?: number;
  max?: number;
}

export interface RegexRule extends ValidationRuleBase {
  type: "string";
  pattern: RegExp;
}

export interface ObjectRule extends ValidationRuleBase {
  type: "object";
  fields: {[k:string]: ValidationRule[]};
}

export interface ArrayRule extends ValidationRuleBase {
  type: "array";
  defaultField: ValidationRule[];
}

export type ValidationRule = RequiredRule | RangeRule | RegexRule | ObjectRule | ArrayRule  | CustomValidatorRule; 


export function getValueByDeepPath(obj: any, path: string) {
    try {
        for (let i = 0, p = path.split("."), len = p.length; i < len; i++) {
            obj = obj[p[i]];
        }
        return obj;
    } catch (err) {
        return undefined;
    }
}

export const BASE_PATH = (() => {
    // process.env.API_BASE_PATH_RUNTIME_JS_EXPR can be any valid Javascript expression that evaluates to a 
    // (1) string, or (2) a function which after being called, returns a string that containing the API BASE PATH URL. e.g. `fhtGlobal.hosts.FhtApiRoot`;
    if (typeof process.env.API_BASE_PATH_RUNTIME_JS_EXPR=== "string") {
        const that = typeof(window) !== "undefined"? window: globalThis as any;
        const key = process.env.API_BASE_PATH_RUNTIME_JS_EXPR;
        const val = getValueByDeepPath(that, key);
        if (typeof val === "string") {
            return val;
        }
        if (typeof val === "function") {
            return val();
        }
		if(typeof window !== "undefined"){
        	if(key.length) throw new Error(`key: ${key} does not evaluate to a valid value`);
		}
    }
    // if API path is not found in window, we will use from environment variable (which is set when running webpack build)
    if (typeof process.env.API_BASE_PATH === "string") {
        return process.env.API_BASE_PATH;
    }
    return process.env.NODE_ENV === "development" ? "" : "";
})();


export function jsonReviver(key:string, value:any) {
    if (typeof value === 'string') {
        if (value.startsWith("20") && /^\d{4}-\d{2}-\d{2}/.test(value)) {
            const d= new Date(value);
            return d;
        }
    }
    return value;
}

export function pad(n: number) {
  return n < 10 ? "0" + n : n;
}

export function toISOString(d: Date) {
  return (
    d.getUTCFullYear() + "-" +
    pad(d.getUTCMonth() + 1) + "-" +
    pad(d.getUTCDate()) + "T" +
    pad(d.getUTCHours()) + ":" +
    pad(d.getUTCMinutes()) + ":" +
    pad(d.getUTCSeconds()) + "Z"
  );
}

function formatParam(p: unknown) {
  if (Object.prototype.toString.call(p) === "[object Date]") {
    return toISOString(p as Date);
  }
  return p;
}


export type AjaxMethod = "GET" | "POST" | "PUT" | "DELETE" | "OPTIONS";


// courtesy of https://stackoverflow.com/questions/54122326/use-reviver-function-in-jquery-ajax-requests
$.ajaxSetup({
  converters: {
    // default was jQuery.parseJSON
    "text json": (data: string) => JSON.parse(data, jsonReviver),
  }
});

export const defaultHeaders: {
    Authorization?: string, 
	"User-Agent"?: string,
} = {};

export interface IApiGlobalConfig {
    basePath: string
    extraAjaxSettings: Partial<JQueryAjaxSettings>
}

export const globalConfig:IApiGlobalConfig = {
    //please use webpack's DefinePlugin to define API_PATH_ROOT
    basePath: BASE_PATH,
    extraAjaxSettings: {}
}


export interface Authentication {
    /**
    * Apply authentication settings to header and query params.
    */
    applyToRequest(requestOptions: JQueryAjaxSettings): void;
}

export class OAuth implements Authentication {
    applyToRequest(requestOptions: JQueryAjaxSettings): void {
        requestOptions.headers = {
            ...requestOptions.headers,
            ...defaultHeaders
        }
    }
}

export class BaseApi {
    protected basePath = globalConfig.basePath;
    protected defaultHeaders : any = {};
    protected auth = new OAuth() as Authentication;

	/** Assert required */
	protected r(params: any, fieldNames: string[]) {
		for (const fieldName of fieldNames) {
			const value = params[fieldName];
			if (value === null || value === undefined) {
				throw new Error(`field: ${fieldName} required`);
			}
		}
	}

    protected ajax(
        method: AjaxMethod,
        localVarPath: string,
        queryParameters: any,
        bodyDict: any,
    ): Promise<any> {
        let url = this.basePath + localVarPath;
        const queryParams: {[k:string]: any} = {};
        for(const [key, val] of Object.entries(queryParameters)){
            if(typeof val !== "undefined"){
                queryParams[key] = formatParam(val);
            }
        }
        if(Object.keys(queryParams).length){
            url = url + "?" + $.param(queryParams);
        }
        let requestOptions: JQueryAjaxSettings = {
            ...globalConfig.extraAjaxSettings,
            url,
            type: method,
            headers: this.defaultHeaders,
            processData: false,
        };
        if (bodyDict && Object.keys(bodyDict).length) {
            requestOptions.data = JSON.stringify(bodyDict);
            requestOptions.contentType = "application/json; charset=utf-8";
        }
        this.auth.applyToRequest(requestOptions);
        return new Promise((resolve: any, reject: any) => {
            $.ajax(requestOptions).then(
                (data: any, textStatus: string, jqXHR: JQueryXHR) => resolve(data),
                (xhr: JQueryXHR, textStatus: string, errorThrown: string) => reject(xhr),
            );
        });
    }    
}





